import { Component } from '@angular/core';

@Component({
    selector: 'tlv-checkout-click-collect-methods-modal',
    templateUrl: './click-collect-modal.component.html',
    styleUrls: ['./click-collect-modal.component.scss'],
    standalone: false
})
export class ClickCollectModalComponent{

}
