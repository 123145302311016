import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from '@components/checkout/checkout.component';
import { CheckoutRoute } from '@components/checkout/checkout.route';
import { OrderSuccessComponent } from '@components/checkout/step/payment/order-success/order-success.component';
import { PaymentFailureComponent } from '@components/checkout/step/payment/payment-failure/payment-failure.component';
import { AuthGuard } from '@services/auth.guard';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OrderService } from '@services/order.service';
import { SalesUserService } from '@services/sales-user.service';

import { OrderRecoveryErrorComponent } from '@components/errors/order-recovery-error/order-recovery-error.component';
import { NotauthorizedComponent } from '@components/guard/notauthorized.component';
import { SalesForceService } from '@services/salesforce.service';
import { CallQualificationComponent } from './components/call-qualification/call-qualification.component';
import { MainComponent } from './components/main.component';

import { SettingsComponent } from '@components/shared/settings/settings.component';
import { WetoService } from '@services/fai/weto.service';
import { NotificationsService } from '@services/notification.service';
import { SkeepersService } from '@services/skeepers.service';

const routes: Routes = [
    {
        path: 'notauthorized',
        component: NotauthorizedComponent
    },
    {
        path: 'client-configuration',
        loadChildren: () =>
            import('./client-configuration/client-configuration.module').then(m => m.ClientConfigurationModule),
    },
    {
        path:'',
        canActivate: [AuthGuard],
        children: [
            {
                path:'erreur-reprise-commande',
                component: OrderRecoveryErrorComponent
            },
            {
                path:'reprise-commande?commandeId',
                redirectTo: 'fai'
            },
            {
                path: '',
                resolve: {
                    salesForce: SalesForceService,
                    isSalesUserLoaded: SalesUserService,
                    mainCartModel: CartTeleSalesService,
                    skeepersService: SkeepersService,
                    notifications: NotificationsService,
                    weto: WetoService
                },
                component: MainComponent,
                children: [
                    {
                        path: 'A360',
                        redirectTo: 'qualification',
                    },
                    {
                        path: 'qualification',
                        component: CallQualificationComponent
                    },
                    {
                        path: 'settings',
                        component: SettingsComponent
                    },
                    {
                        path: 'products',
                        loadChildren: (): any => import('./components/products-walls/products-walls.module').then(m => m.ProductsWallsModule)
                    },
                    {
                        path: 'fai',
                        loadChildren: (): any => import('./components/fai-widget/fai-widget.module').then(m => m.FaiWidgetModule)
                    },
                    {
                        path: 'panier',
                        component: CheckoutComponent,
                        children: CheckoutRoute
                    },
                    {
                        path: 'order-success/:orderId',
                        component: OrderSuccessComponent,
                        resolve: {
                            order: OrderService
                        }
                    },
                    {
                        path: 'payment-failure/:orderId',
                        component: PaymentFailureComponent,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'qualification',
                    },
                    {
                        path: '**',
                        redirectTo: '/qualification'
                    }
                ]
            }
        ]
    }
];

const scrollOffsetOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [
        0,
        120 // Add header height on offset calculation
    ],
    bindToComponentInputs: true // Allow input binding on activatedRoute data
};

@NgModule({
    imports: [RouterModule.forRoot(routes, scrollOffsetOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
