<header class="has-background-white container-fluid">
  <div class="header-layout">
    <div class="tlv-logo has-background-grey-light">
      <h1 class="has-text-centered">
        <span class="is-hidden" aria-hidden="false">Logo Bouygues Télécom</span>
        <span class="icon is-large"><i class="tri-logo"></i></span>
      </h1>
    </div>
    @if (showMenuLinks && !checkRoute()) {
      <div class="header-info">
        <ul class="columns is-vcentered-desktop is-marginless">
          @for (menuLink of menuLinks; track menuLink) {
            @if (menuLink.urlFragment) {
              <li (click)="onMenuClick(menuLink)" [data-cy]="'menu-'+menuLink.dataCy" [class.has-text-grey]="isActive(menuLink)" class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [routerLink]="[menuLink.link]" [fragment]="menuLink.urlFragment">
                <span class="icon is-medium">
                  <i [ngClass]="menuLink.icon"></i>
                </span>
                <div class="popover-content">
                  <p>{{menuLink.label}}</p>
                </div>
              </li>
            } @else {
              @if (menuLink.isExternal) {
                <li class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [data-cy]="'menu-'+menuLink.dataCy" [class.has-text-grey]="isActive(menuLink)">
                  <a class="has-text-tertiary" [href]="menuLink.link" rel="noopener noreferrer" target="_blank">
                    <span class="icon is-medium">
                      <i [ngClass]="menuLink.icon"></i>
                    </span>
                    <div class="popover-content">
                      <p>{{menuLink.label}}</p>
                    </div>
                  </a>
                </li>
              } @else {
                <li class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [data-cy]="'menu-'+menuLink.dataCy" (click)="onMenuClick(menuLink)"  [class.has-text-grey]="isActive(menuLink)" [routerLink]="[menuLink.link]">
                  <span class="icon is-medium">
                    <i [ngClass]="menuLink.icon"></i>
                  </span>
                  <div class="popover-content">
                    <p>{{menuLink.label}}</p>
                  </div>
                </li>
              }
              <ng-template #routerLink>
                <li class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [data-cy]="'menu-'+menuLink.dataCy" (click)="onMenuClick(menuLink)"  [class.has-text-grey]="isActive(menuLink)" [routerLink]="[menuLink.link]">
                  <span class="icon is-medium">
                    <i [ngClass]="menuLink.icon"></i>
                  </span>
                  <div class="popover-content">
                    <p>{{menuLink.label}}</p>
                  </div>
                </li>
              </ng-template>
            }
          }
          <li class="column is-flex is-vcentered-desktop is-positionned-right">
            <span><strong>{{customer?.gender}} {{customer?.lastname}} {{customer?.firstname}}</strong></span>
          </li>
          @if (notificationsList.length > 0) {
            <li class="column is-narrow is-flex is-vcentered">
              <a class="icon is-small has-status is-ancestor has-text-tertiary" (click)="toggleNotificationsPanel()" data-cy="notifications-open-modal">
                <span class="tri-bell" aria-hidden="true">
                </span>
                @if (unreadNotifications.length > 0) {
                  <span
                    class="total-notification is-animating icon is-circled is-descendant is-size-8 has-text-weight-bold has-text-white"
                    [ngClass]="[notificationClass]"
                    data-cy="notifications-length">
                    {{unreadNotifications.length}}
                  </span>
                }
              </a>
            </li>
          }
          <li class="column is-narrow has-text-right is-paddingless">
            <button data-cy="close-call-btn" class="button is-primary" (click)="closeCall()">
              <span class="icon-and-text">
                <span class="icon is-medium icon-rotation">
                  <i class="tri-phone-outgoing-call"></i>
                </span>
                <span>Terminer session</span>
              </span>
            </button>
          </li>
        </ul>
      </div>
    }
    @if (!IsEnvProd && checkRoute()) {
      <div class="header-info">
        <ul class="columns is-vcentered-desktop is-marginless">
          @for (privateMenuLink of privateMenuLinks; track privateMenuLink) {
            <li (click)="onMenuClick(privateMenuLink)" [data-cy]="'menu-'+privateMenuLink.dataCy" [class.has-text-grey]="isActive(privateMenuLink)" class="column is-narrow popover is-popover-right has-cursor" [routerLink]="[privateMenuLink.link]" [fragment]="privateMenuLink.urlFragment">
              <span class="icon is-medium">
                <i [ngClass]="privateMenuLink.icon"></i>
              </span>
              <div class="popover-content">
                <p>{{privateMenuLink.label}}</p>
              </div>
            </li>
          }
        </ul>
      </div>
    }
    <span class="user-logout has-cursor is-narrow has-background-success has-text-centered has-text-white" (click)="logout()">
      <span class="icon is-small"><i class="tri-power"></i></span>
      <p class="is-size-6 has-text-weight-medium clip-username">{{salesUser?.username}}</p>
    </span>
  </div>

</header>

@if (displayNotificationsPanel) {
  <tlv-notifications-panel (closeModal)="toggleNotificationsPanel()"></tlv-notifications-panel>
}
