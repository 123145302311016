import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Accessory, Fai, Phone, QuoteModel } from '@bytel/bytel-sales';
import { CatalogService } from '../catalog.service';
import { CartTeleSalesService } from './cart-telesales.service';
import { DisplayAccessoryModel } from '@models/cart/display-accessory.model';
import bind from 'src/app/helper/decorators/bind';
import { OrderModel } from '@models/order/order.model';
import { SalesForceService } from '@services/salesforce.service';
import { MainCartModel } from '@models/cart/main-cart.model';
import { EquipmentDetailsModel } from '@models/equipment-details.model';

@Injectable({
    providedIn: 'root'
})
export class AccessoryService {
    public update$: Observable<DisplayAccessoryModel[]>;
    public accessories: DisplayAccessoryModel[] = [];

    private _orderRecovery: OrderModel;
    private _subjectUpdate = new BehaviorSubject<DisplayAccessoryModel[]>([]);
    private avalaibleAccessories = new Map<string, DisplayAccessoryModel[]>();

    constructor(
        private catalogService: CatalogService,
        private cartTeleSalesService: CartTeleSalesService,
        private salesForceService: SalesForceService
    ) {
        this._orderRecovery = this.salesForceService.prefilledInfo.order;
        this.update$ = this._subjectUpdate.asObservable();
        this.cartTeleSalesService.refreshObs.pipe(
            mergeMap((cartModel: MainCartModel) => this._orderRecovery ? this._loadAccessoriesFromOrderRecovery() :
                this._updateCurrentAccessories(cartModel))
        ).subscribe((accessories)=>{
            if (this.accessories.length === accessories.length &&
                accessories.every(o=>this.accessories.some(oo=>oo.gencode === o.gencode))){
                return;
            }
            this.accessories = accessories;
            this._subjectUpdate.next(this.accessories);
        });
    }

    private _loadAccessoriesFromOrderRecovery(): Observable<DisplayAccessoryModel[]> {
        return this._getAccessories(
            null,
            // Avoid the display of the same product twice since the quantity is handled with a dropdown
            [...new Set(this._orderRecovery.cart.accessories.map(acc => acc.gencode))]
        );
    }

    private _updateCurrentAccessories(cart: MainCartModel): Observable<DisplayAccessoryModel[]> {
        return this._getAccessoriesQuote(cart.getQuote());
    }

    private _getAccessoriesQuote(quote: QuoteModel): Observable<DisplayAccessoryModel[]> {
        const products = [].concat(quote.getProductsByType<Phone>('Phone'), quote.getProductsByType<Fai>('Fai', true));
        if (!products.length) {
            return of([]);
        }
        const obs: Observable<DisplayAccessoryModel[]>[] = [];
        products.forEach((product: Phone | Fai) => {
            obs.push(this._getAccessories(product.gencode, product.crossSells));
        });
        return forkJoin(obs).pipe(
            map((acc) => [].concat(...acc))
        );
    }

    private _getAccessories(productGencode: string, accessoriesGencode: string[],
                            withCurrentAcc = true): Observable<DisplayAccessoryModel[]> {
        return forkJoin([
            this.catalogService.getProductsByGencodes<Accessory>(accessoriesGencode),
            this.catalogService.getProductDetails(productGencode)
        ]).pipe(
            map(([accessories, productDetails]) => accessories.filter((acc) => acc.isInStock()).map((acc) => new DisplayAccessoryModel(
                acc.data,
                true,
                accessoriesGencode.findIndex(upSell=>upSell === acc.gencode) ? acc.position : 0,
                productDetails instanceof EquipmentDetailsModel ?
                    productDetails.crossSellsProducts.find(crossSell => crossSell.gencode === acc.gencode)?.essential :
                    false
            ))),
            map((accessories) => this._sortAccessoriesBy(accessories, 'position')),
            tap((accessories) => {
                if (!withCurrentAcc){this.avalaibleAccessories.set(productGencode,accessories);}
            }));
    }

    @bind
    private _sortAccessoriesBy(accessories: DisplayAccessoryModel[], attribute: string): DisplayAccessoryModel[]{
        return accessories.sort((accessoryA: DisplayAccessoryModel, accessoryB: DisplayAccessoryModel) =>
            Math.sign(accessoryA[attribute] - accessoryB[attribute])
        );
    }
}
