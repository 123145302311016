<section [ngClass]="isLoading && 'is-loading'">
  <div class="box is-flat is-borderless is-radiusless">
    <div class="box-content has-text-centered">
      <tlv-words-suggestion limiter="@" [close]="isSubmitted" [source]="$any(searchForm.get('searchItem'))">
      </tlv-words-suggestion>
      <div class="is-flex is-centered">
        <div id="search-form" class="is-11 is-paddingless column">
          <form [formGroup]="searchForm" (submit)="onSearch()" [ngClass]="{'is-vcentered': !showIdProspectInput && (!searchForm.get('searchItem').errors?.badEmail)}">
            <div class="columns is-centered is-vcentered">
              <div class="column is-5">
                @if (showIdProspectInput) {
                  <div class="columns">
                    <div class="column is-narrow">
                      <span
                      class="icon is-circled is-small has-background-grey-light has-text-weight-bold">1</span>
                    </div>
                    <div class="column is-narrow">
                      <span class="is-size-5">Adresse email ou MSISDN ou BBOX</span>
                    </div>
                  </div>
                }
                <input data-cy="input-search" tlvFormClass
                  (paste)="onPaste($event)"
                  (keyup)="onKeyUp($event)"
                  [tlvFormClassErrorControl]="searchForm.get('searchItem')" class="input"
                  formControlName="searchItem" id="email" maxlength="100"
                  placeholder="Veuillez saisir l'adresse email ou MSISDN ou BBOX" type="text">
                @if (searchForm.get('searchItem').invalid && searchForm.get('searchItem').touched) {
                  <ul
                    class="help is-danger">
                    @if (searchForm.get('searchItem').errors?.badEmail) {
                      <li data-cy="bad-email">
                      {{searchForm.get('searchItem').errors?.badEmail}}</li>
                    }
                  </ul>
                }
              </div>
            </div>
            <div class="columns is-centered is-vcentered">
              @if (showIdProspectInput) {
                <div class="column is-5">
                  <div class="columns">
                    <div class="column is-narrow">
                      <span
                      class="icon is-circled is-small has-background-grey-light has-text-weight-bold">2</span>
                    </div>
                    <div class="column is-narrow">
                      <span class="is-size-5">Id PRM* du prospect</span>
                    </div>
                  </div>
                  <input data-cy="input-id-prm"
                    [ngClass]="{'is-success': searchForm.get('idPrm')?.value?.length >= 12 && searchForm.get('idPrm')?.value?.length <= 13}"
                    class="input" formControlName="idPrm" maxlength="13"
                    placeholder="Veuillez saisir l'id PRM du prospect" type="text" InputOnlyNumber>
                  <p class="help has-text-left">
                    * Un IDPRM doit être compris entre 12 et 13 caractères numériques
                  </p>
                </div>
              }
            </div>
            <div class="columns is-multiline is-centered">
              @if (searchForm.valid && !isCompleted) {
                <div class="column is-12 has-text-grey">
                  <p data-cy="desc-client">Vous allez lancer une recherche sur @if (!searchForm.get('searchItem')?.value.includes('@')) {
                    <span>le
                    numéro de téléphone </span>
                    }@if (searchForm.get('searchItem')?.value.includes('@')) {
                    <span>l'adresse email </span>
                  }
                  @if (searchForm.get('searchItem').valid) {
                    <span class="has-text-weight-semibold"> {{
                    searchForm.get('searchItem').value }}</span>
                  }</p>
                </div>
              }
              <div class="column is-narrow" [ngClass]="{'btn-prospect': showIdProspectInput}">
                <br />
                <button data-cy="btn-search" type="submit" class="button"
                  [disabled]="searchForm.invalid || isLoading || isCompleted || searchForm.disabled"
                                    [ngClass]="{
                                        'is-primary': searchForm.valid && !isCompleted,
                                        'is-danger': searchForm.invalid || isLoading || isCompleted,
                                        'is-tertiary': searchForm.disabled
                                    }">Rechercher</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <tlv-partial-scoring />
        </div>
      </div>
      <div class="columns is-8 is-marginless is-centered is-multiline">
        @if ((searchObject?.infoMessage || searchObject?.descriptionError) && isCompleted) {
          <div class="column is-12 has-text-centered"
            >
            <div class="is-inline-block">
              <div class="notification is-small has-body"
                [class]="searchObject?.isError ? 'is-danger' : 'is-success'">
                <div class="icon">
                  <i
                  [class]="searchObject?.isError ? 'tri-exclamation-circle' : 'tri-check-circle'"></i>
                </div>
                <div class="body">
                  <h5 class="title" data-cy="msg-error">{{ searchObject.infoMessage || searchObject.descriptionError }}</h5>
                </div>
              </div>
            </div>
            <br>
              @if (searchObject.isError) {

                <div class="is-pro-checkbox is-flex is-centered control">
                    <input (change)="isPro = !isPro" data-cy="is-pro-checkbox" class="is-checkradio" id="is-pro" type="checkbox">
                    <label data-cy="is-pro-label" class="is-size-7" for="is-pro">
                      Le client confirme être un professionel
                    </label>
                </div>
                <button [class.is-loading]="isLoading" [disabled]="isSearchLocked || isLoading" (click)="continueProspect()"
                  data-cy="continue-prospect" type="button" class="button is-secondary">Continuer en tant que
                prospect</button>
              }
            </div>
          }
        </div>
        @if (isCompleted && searchObject.isError !== true) {
          <tlv-customer-info [customer]="customer" [searchObject]="searchObject" (savedEvent)="onClose($event)" />
        }
        @if (isCompleted && customer) {
          <tlv-proposals data-cy="proposal-block" [customer]="customer" (savedEvent)="onClose($event)" />
        }
      </div>
    </div>
  </section>
