import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tlv-words-suggestion',
    templateUrl: './words-suggestion.component.html',
    styleUrls: ['./words-suggestion.component.scss'],
    standalone: false
})
export class WordsSuggestionComponent implements OnChanges, OnDestroy {

    @Input() public limiter: string;
    @Input() public source: FormControlName | string;
    @Input() close: boolean;

    public readonly wordsSuggestions: Record<string, string> = {
        a: 'Agathe',
        b: 'Benoit',
        c: 'Claire',
        d: 'Damien',
        e: 'Edith',
        f: 'Franck',
        g: 'Gregory',
        h: 'Henry',
        i: 'Isabelle',
        j: 'Julien',
        k: 'Karen',
        l: 'Lucie',
        m: 'Marie',
        n: 'Nicolas',
        o: 'Olivier',
        p: 'Pierre',
        q: 'Quentin',
        r: 'Romain',
        s: 'Sophie',
        t: 'Thomas',
        u: 'Ulysse',
        v: 'Victor',
        w: 'William',
        x: 'Xavier',
        y: 'Yann',
        z: 'Zoé',
        _: 'tiret du bas',
        ['.']: 'point',
        [',']: 'virgule',
        [';']: 'point virgule',
        ['@']: 'arobase',
        ['-']: 'tiret',
        ['+']: 'plus',
    };
    public suggestions: string[] = [];
    public currentString = '';
    private _subscriptions: Subscription[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (typeof this.source === 'string') {
            this._handleValue(this.source);
        } else {
            this._subscriptions.push(
                this.source.valueChanges.subscribe((val: string) => this._handleValue(val.substring(val.length - 1), val))
            );
        }

        if (!changes.close?.firstChange && !!changes?.close.currentValue) {
            this.suggestions = [];
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

    private _handleValue(value: string, fullWord?: string): void {

        if (fullWord.includes(this.limiter) || /\d/.test(value)) {
            return;
        }

        this.suggestions = [];
        if (!value) {
            this.currentString = this.suggestions.join('');
            return;
        }

        for (const letter of fullWord.replace(/\d/g, '')) {
            this.suggestions.push(letter.toLocaleLowerCase());
        }
        this.currentString = this.suggestions.join('');
    }

}
