import {ErrorHandler, Injectable} from '@angular/core';
import {PrismeLoggerService} from '@services/prisme-logger.service';
import {HttpErrorResponse} from '@angular/common/http';
import { PrismeLogType } from '../constants/prisme';

@Injectable()
export class ErrorInterceptor extends ErrorHandler {
    constructor(private prismeLoggerService: PrismeLoggerService) {
        super();
    }
    public handleError(error): void {
        if (error instanceof HttpErrorResponse) {
            this.prismeLoggerService.sendDataToPrisme(PrismeLogType.ERROR_HTTP, { message: `${error.message}`});
        } else {
            this.prismeLoggerService.sendDataToPrisme(PrismeLogType.ERROR_JS, { message: `${error.message}` });
        }
    }
}
