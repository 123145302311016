<form id="form" [formGroup]="ibanForm" autocomplete="chrome-off">
  <!-- HACK: DISABLE CHROME AUTOCOMPLETE -->
  <input style="opacity: 0;position: absolute;">
  <input type="password" style="opacity: 0;position: absolute;">
  <!-- -->
  @if (ibans.length) {
    <div class="control" >
      <div class="select is-info is-fullwidth">
        <select data-cy="customer-iban-select" formControlName="customerIban">
          <option selected value="0">Sélectionnez un Iban</option>
          @for (iban of ibans; track iban; let i = $index) {
            <option [value]="i+1">{{iban.masked}}</option>
          }
        </select>
      </div>
    </div>
    @if (ibans.length) {
      <div class="field">
        <div class="control">
          <input
            data-cy="use-customer-iban-for-billing"
            class="is-checkradio"
            id="use-for-purchase"
            type="checkbox"
            formControlName="useIbanForPurchase">
          <label data-cy="use-customer-iban-for-billing-label" for="use-for-purchase">Utiliser cet IBAN pour mon achat</label>
        </div>
      </div>
    }
  }
  @if (!ibanForm.get('useIbanForPurchase').value || !ibans.length || (!ibanForm.get('useIbanForPurchase').value && cartHasMobileRecovery)) {
    <div class="field">
      <p class="has-text-weight-semibold" bytelTranslate="panier.paiement.iban.titre">IBAN</p>
      @if (maskedOrderRecoveryIban) {
        <p class="has-text-grey is-size-6" data-cy="recovery-masked-iban">{{maskedOrderRecoveryIban}}</p>
      }
      <div class="control has-icons-right" [class.is-hidden]="maskedOrderRecoveryIban">
        <input
          data-cy="custom-iban-input"
          autocomplete="none"
          class="input"
          type="text"
          formControlName="iban"
          tlvFormClass
          [tlvFormClassErrorControl]="ibanForm.get('iban')"
          placeholder="Saisissez votre IBAN" id="iban">
        @if (ibanForm.get('iban').touched) {
          <span class="icon is-small">
            <i
            [ngClass]="{'tri-check-circle has-text-success': ibanForm.get('iban').valid, 'tri-exclamation-circle has-text-danger': ibanForm.get('iban').invalid && ibanForm.get('iban').value}"></i>
          </span>
        }
      </div>
      @if (cartHasMobileRecovery) {
        <p>Cet iban sera utilisé pour le versement de la reprise mobile.</p>
      }
      @if (orderRecovery) {
        <p>Cet iban sera utilisé pour la reprise commande.</p>
      }
    </div>
    @if (shouldShowIbanError) {
      <ul
        data-cy="iban-input-errors" class="help is-danger">
        @if (checkOpenBankingStatusMessage?.length > 0) {
          <li><i
          class="tri-infos-circle"></i> {{checkOpenBankingStatusMessage}}</li>
        }
        @if (ibanForm.get('iban').errors?.required) {
          <li bytelTranslate="panier.paiement.iban.erreur.obligatoire"><i class="tri-infos-circle"></i> La saisie de l'IBAN est obligatoire</li>
        }
        @if (ibanForm.get('iban').errors?.pattern) {
          <li bytelTranslate="panier.paiement.iban.erreur.invalide"><i class="tri-infos-circle"></i> Veuillez saisir un IBAN correct</li>
        }
        @if (ibanForm.get('iban').errors?.ibanApi) {
          <li><i class="tri-infos-circle"></i> {{ibanForm.get('iban').errors?.ibanApi.message}}</li>
        }
        @if (ibanForm.get('iban').errors?.minlength) {
          <li><i class="tri-infos-circle"></i> Merci de fournir un Iban avec au moins {{ibanForm.get('iban').errors?.minlength.requiredLength}} caractères pour un iban de type <span class="is-uppercase">{{currentCountryCode}}</span></li>
        }
      </ul>
    }
    @if (ibanForm.get('iban').valid && canalPlusElig) {
      <div class="notification is-small has-body is-info">
        <div class="icon">
          <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
          <h5 bytelTranslate="panier.paiement.iban.canalPlus.notif1" class="title">Vous pouvez continuez votre commande.</h5>
          <p bytelTranslate="panier.paiement.iban.canalPlus.notif2">Cependant nous ne sommes pas en mesure de donner suite à votre demande de souscription à CANAL +
            Veuillez contacter le Service Client CANAL au 0 892 39 39 10 (0,35 €/min + prix d'appel)
          </p>
        </div>
      </div>
    }
  }
</form>
