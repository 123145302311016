@use "sass:string";

%delete {
    @extend %unselectable;

    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba($black, 0.2);
    border: none;
    border-radius: $radius-rounded;
    cursor: pointer;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    outline: none;
    position: relative;
    vertical-align: top;
    width: 20px;

    &::before,
    &::after {
        background-color: $white;
        content: '';
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
    }

    &::before {
        height: 2px;
        width: 50%;
    }

    &::after {
        height: 50%;
        width: 2px;
    }

    &:hover,
    &:focus {
        background-color: rgba($black, 0.3);
    }

    &:active {
        background-color: rgba($black, 0.4);
    }

    // Sizes
    &.is-small {
        height: 16px;
        max-height: 16px;
        max-width: 16px;
        min-height: 16px;
        min-width: 16px;
        width: 16px;
    }

    &.is-medium {
        height: 24px;
        max-height: 24px;
        max-width: 24px;
        min-height: 24px;
        min-width: 24px;
        width: 24px;
    }

    &.is-large {
        height: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
        width: 32px;
    }
}

%unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin delete {
    @extend %delete;
}

@mixin arrow($color, $direction: 'down') {
    background-color: $color;
    @if $direction == 'up' {
      content: "";
      -webkit-mask-image: url('./assets/svg/all/arrow-up.svg');
      mask-image: url('./assets/svg/all/arrow-up.svg');
    } @else if $direction == 'left' {
      content: "";
      -webkit-mask-image: url('./assets/svg/all/arrow-left.svg');
      mask-image: url('./assets/svg/all/arrow-left.svg');
    } @else if $direction == 'right' {
      content: "";
      -webkit-mask-image: url('./assets/svg/all/arrow-right.svg');
      mask-image: url('./assets/svg/all/arrow-right.svg');
    } @else {
      content: "";
      -webkit-mask-image: url('./assets/svg/all/arrow-down.svg');
      mask-image: url('./assets/svg/all/arrow-down.svg');
    }

  min-width: 15px;
  min-height: 15px;
  color: $color;
    position: absolute;
    display: inline-block;
}

@mixin block {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

@mixin clearfix {
    &::after {
        clear: both;
        content: ' ';
        display: table;
    }
}

@mixin center($width, $height: 0) {
    position: absolute;

    @if $height != 0 {
        left: calc(50% - (#{$width} / 2));
        top: calc(50% - (#{$height} / 2));
    } @else {
        left: calc(50% - (#{$width} / 2));
        top: calc(50% - (#{$width} / 2));
    }
}

@mixin spinner {
    animation: spinAround 500ms infinite linear;
    border: 2px solid $border;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
}

@mixin overflow-touch {
    -webkit-overflow-scrolling: touch;
}

@mixin overlay($offset: 0) {
    position: absolute;
    top: $offset;
    bottom: $offset;
    left: $offset;
    right: $offset;
}

@mixin placeholder {
    $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';

    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

@mixin unselectable {
    @extend %unselectable;
}

@mixin ui-icon {
    font-family: trilogy-all;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0.2em;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* remove if not needed */
    margin-left: 0.2em;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Responsiveness
@mixin from($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}

@mixin until($device) {
    @media screen and (max-width: $device - 1px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $tablet - 1px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet), print {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
        @content;
    }
}

@mixin touch {
    @media screen and (max-width: $desktop - 1px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin desktop-only {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
        @content;
    }
}

@mixin until-widescreen {
    @media screen and (max-width: $widescreen - 1px) {
        @content;
    }
}

@mixin widescreen {
    @media screen and (min-width: $widescreen) {
        @content;
    }
}

@mixin widescreen-only {
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
        @content;
    }
}

@mixin until-fullhd {
    @media screen and (max-width: $fullhd - 1px) {
        @content;
    }
}

@mixin fullhd {
    @media screen and (min-width: $fullhd) {
        @content;
    }
}

@mixin all-desktop-only {
    @media screen and (min-width: $desktop) and (max-width: $fullhd - 1px) {
        @content;
    }
}

@mixin hamburger($hamburger-dimensions) {
    cursor: pointer;
    display: block;
    height: $hamburger-dimensions;
    position: relative;
    width: $hamburger-dimensions;

    span {
        background-color: currentColor;
        display: block;
        height: 1px;
        left: 50%;
        margin-left: -7px;
        position: absolute;
        top: 50%;
        transition: none $speed $easing;
        transition-property: background, left, opacity, transform;
        width: 15px;

        &:nth-child(1) {
            margin-top: -6px;
        }

        &:nth-child(2) {
            margin-top: -1px;
        }

        &:nth-child(3) {
            margin-top: 4px;
        }
    }

    &:hover {
        background-color: rgba(black, 0.05);
    }

    // Modifers
    &.is-active {
        span {
            &:nth-child(1) {
                margin-left: -5px;
                transform: rotate(45deg);
                transform-origin: left top;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                margin-left: -5px;
                transform: rotate(-45deg);
                transform-origin: left bottom;
            }
        }
    }
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

@mixin text-tags() {
    a,
    span,
    p,
    i,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    label,
    h6 {
        @content;
    }
}

@mixin heading-tags() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @content;
    }
}

@mixin apply-shadow-box {
    box-shadow: $shadow;
}

@mixin apply-colors($property: 'color') {
    @each $name, $pair in $colors-brand-funcionnal {
        $color: nth($pair, 1);

        &.is-#{$name} {
            #{$property}: $color;
        }

        .is-admin & {
            #{$property}: $admin-invert;
        }
    }
}

@mixin pattern-light($size, $x-pos: top, $y-pos: center) {
    background-size: $size;
    background-position: $x-pos $y-pos;
}

@mixin pattern-dark($size, $x-pos: top, $y-pos: center) {
    background: linear-gradient(#1b3650, $secondary);
    background-size: auto, $size, auto;
    background-position: top left, $x-pos $y-pos, top left;
}

// import sass bulma
@mixin input {
    @include control;
    height: 56px;
    background-color: $input-background-color;
    border-color: $input-border-color;
    color: $input-color;

    @include placeholder {
        color: $grey !important;
        opacity: 1 !important;
        transition: all 0.2s ease-out;
    }

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
        outline: 1px auto darken($secondary, 5%) !important;
    }

    &[disabled] {
        background-color: $input-disabled-background-color;
        border-color: $input-disabled-border-color;
        color: $input-disabled-color;

        @include placeholder {
            color: $input-disabled-color;
        }
    }
}

@mixin checkradio {
    outline: 0;
    user-select: none;
    display: inline-block;
    vertical-align: baseline;
    position: absolute;
    opacity: 0;

    + label {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
            &::before {
                animation-duration: 0.4s;
                animation-fill-mode: both;
                animation-name: hover-color;
            }
        }

        &::before,
        &::after {
            content: '';
        }

        &::before {
            border: 1px solid $grey;
            margin: 0 10px 0 0;
            min-width: 16px;
            min-height: 16px;
        }

        &::after {
            position: absolute;
            display: none;
        }
    }
}

@mixin checkbox-and-radio($type) {
    //BOTH
    .#{$type},
    .#{$type}-tile {
        position: relative;

        label.#{$type}-label {
            cursor: pointer;
            font-size: 14px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 18px;
                height: 18px;
            }

            &:before {
                border: 1px solid $grey;
            }
        }

        input {
            display: none;

            &:checked ~ label.#{$type}-label {
                border-color: $secondary;

                &:before {
                    border-color: $secondary;
                }

                &:after {
                    background-color: $secondary;
                }
            }

            &:disabled,
            &:disabled:checked {
                & ~ label.#{$type}-label {
                    cursor: no-drop;
                    color: $grey-80;
                    border-color: $grey-disabled;

                    &:before {
                        border-color: $grey-disabled;
                    }

                    &:after {
                        background-color: $grey-disabled;
                    }
                   .icon {
                    [class^="tri-"],
                    [class*=" tri-"] {
                      background-color: $grey-80 !important;
                    }
                  }
                }
            }

            &:disabled {
                & ~ label.#{$type}-label {
                    &:after {
                        background-color: transparent;
                    }
                }
             & .icon {
                background-color: $grey-80;
              }
            }
        }
    }

    // SIMPLE WITH LABEL ONLY
    .#{$type} {
        display: flex;
        align-items: center;
        margin: 6px 8px;

        label.#{$type}-label {
            padding: 4px;
            padding-left: 34px;

            &:before,
            &:after {
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }

        input:checked ~ label.#{$type}-label {
            font-weight: bold;
        }

        input:not(:disabled) ~ label.#{$type}-label {
            &:hover {
                &:before {
                    border-color: $secondary;
                    border-width: 2px;
                }
            }
        }
    }

    //TILE ONLY
    .#{$type}-tile {
        position: relative;
        max-width: 160px;
        display: inline-block;
        margin: 0;
        margin-right: 16px;
        margin-top: 16px;
        text-align: center;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        label.#{$type}-label {
            display: block;
            position: relative;
            padding: 40px 16px 16px;
            border: 1px solid $tertiary;
            border-radius: 8px;
            box-sizing: border-box;

            &:before {
                top: 16px;
                right: 16px;
            }

            span.#{$type}-title {
                font-weight: bold;
                width: 100%;
                display: block;
                font-size: 16px;
            }
        }

        input {
            &:not(:disabled) ~ label.#{$type}-label {
                &:focus,
                &:active,
                &:hover {
                    border-color: $secondary;
                    border-width: 2px;
                    padding: 15px;
                    padding-top: 39px;

                    &:before {
                        top: 15px;
                        right: 15px;
                    }
                }
            }

            &:checked:not(:disabled) ~ label.#{$type}-label {
                border-color: $secondary;
                border-width: 2px;
                padding: 15px;
                padding-top: 39px;

                &:before {
                    top: 15px;
                    right: 15px;
                }

                span.#{$type}-title {
                    color: $secondary;
                }
            }

            &:disabled {
                & ~ label.#{$type}-label {
                    background-color: $grey-disabled;
                    color: $grey-dark !important;

                    &:hover {
                        border-width: 1px;
                    }

                    &:focus,
                    &:active,
                    &:hover {
                        padding: 16px;
                        padding-top: 40px;
                    }

                    &:before {
                        border-color: $grey !important;
                        box-sizing: inherit;
                    }
                }
            }

            &:disabled:checked ~ label.#{$type}-label {
                &:before {
                    border-color: $grey !important;
                    box-sizing: inherit;
                }

                .checkbox-title {
                    color: $grey-dark;
                }
            }
        }

        &.is-horizontal {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: unset;
            width: auto;
            margin: 16px 0;
            position: relative;

            &.is-narrow{
              width: fit-content;
            }

            input {
                &:checked:not(:disabled) ~ label.#{$type}-label {
                    padding: 15px;
                    padding-right: 47px;
                }

                &:disabled {
                    & ~ label.#{$type}-label {
                        &:focus,
                        &:active,
                        &:hover {
                          padding: 16px 48px 16px 16px !important;
                        }
                    }
                }
            }

            label.#{$type}-label {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                padding: 16px 48px 16px 16px;

                &:focus,
                &:active,
                &:hover {
                    padding: 15px 47px 15px 15px !important;
                }

                &:before,
                &:after {
                    top: 50% !important;
                    transform: translateY(-50%);
                }
            }

            .icon {
                margin-right: 16px;
            }
        }
    }
}
