import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    public modalReady = false;
    public modalSubject: Subject<boolean> = new Subject<boolean>();
}
