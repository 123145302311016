<div class="is-flex is-fullwidth is-vcentered" data-cy="notification-item">
  @if (notification.priority) {
    <div class="is-flex is-vcentered" data-cy="notification-priority">
      <span class="icon is-small is-primary"><i class="tri-exclamation-circle" aria-hidden='true'></i></span>
      <span>&nbsp;&nbsp;</span>
    </div>
  }
  <span class="tag is-small"
  [ngClass]="notificationTypesClassMap[notification.notificationType]">{{notificationTypesLabelMap[notification.notificationType]}}</span>
  <span>&nbsp;&nbsp;</span>
  @if (notification.isRead || showDescription !== undefined) {
    <span [data-cy]="'notification-'+notification.id+'-is-read'" class="tag is-small is-success">Lu</span>
  }
  <span>&nbsp;&nbsp;</span>
  <div class="is-flex is-fullwidth is-justified-end">
    <span
    class="has-text-weight-normal has-text-tertiary paragraph is-3 text is-marginless" data-cy="notification-date">{{notification.startDate | date:'dd/MM/yy'}}</span>
  </div>
</div>
<p></p>
<p class="title is-size-5 is-marginless" data-cy="notification-title">
  {{notification.title}}
</p>
<div class="text is-1 word-break" [hidden]="showDescription">
  <div class="intro is-inline" [innerHTML]="notification.description | slice:0:75" data-cy="notification-description"></div>
  <div class="has-text-right">
    <a class="link" [data-cy]="'notification-'+notification.id+'-read-more'" (click)="toggleDescription()" data-cy="notifications-read-more">Lire la suite</a>
  </div>
</div>
<div class="text is-1 word-break" [hidden]="!showDescription" data-cy="notifications-description">
  <div class="description" [innerHTML]="notification.description"></div>
  <div class="has-text-right">
    <a class="link" (click)="toggleDescription()" data-cy="notifications-read-less">Fermer</a>
  </div>
</div>