import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { REGEXS } from '@common-modules';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AddressModel } from '@models/cart/address.model';
import { AddressPredictionModel } from '@models/address-prediction.model';
import bind from '../../../../../helper/decorators/bind';
import { AddressAutocompleteService } from '@services/address-autocomplete.service';
import { ITlvAutocompleteItem } from '../../../../../directive/autocomplete.directive';

@Component({
    selector: 'tlv-automatic-address',
    templateUrl: './automatic-address.component.html',
    styleUrls: ['./automatic-address.component.scss'],
    standalone: false
})
export class AutomaticAddressComponent implements OnChanges{

    public prediction: ITlvAutocompleteItem<AddressPredictionModel>;
    public submitted = false;
    public loading = false;
    public complementaryAddress = '';
    public addressWithOutComplement: AddressModel;
    public isSearching = false;
    public REGEXS: typeof REGEXS = REGEXS;

    @Input()  value: AddressModel;
    @Output() valueChange = new EventEmitter<AddressModel>();

    constructor(
        protected addressAutocompleteService: AddressAutocompleteService
    ) { }

    @bind
    public getValuesAutoComplete(value: string): Observable<ITlvAutocompleteItem<AddressPredictionModel>[]>{
        this.isSearching = true;
        return this.addressAutocompleteService.getPrediction(value).pipe(
            map(predictions => predictions.map(prediction => ({
                label:prediction.address,
                data: prediction
            }))),
            finalize(()=>this.isSearching = false)
        );
    }

    @bind
    public selectAddress(addressPrediction: ITlvAutocompleteItem<AddressPredictionModel>): void {
        if (!addressPrediction){
            this.valueChange.emit(null);
        } else {
            this.addressAutocompleteService.getAddressOfPrediction(addressPrediction.data)
                .subscribe((data) => {
                    this.addressWithOutComplement = data;
                    data.complement = this.complementaryAddress;
                    this.valueChange.emit(data);
                });
        }
    }

    public complementChange(): void{
        this.addressWithOutComplement.complement = this.complementaryAddress;
        this.valueChange.emit(this.addressWithOutComplement);
    }

    public ngOnChanges(): void {
        this.prediction = {
            label: this.value ? this.value.getInline(false) : '',
            data: new AddressPredictionModel({
                address: this.value ? this.value.getInline(false) : ''
            })
        };
        this.addressWithOutComplement = this.value;
        this.complementaryAddress = this.value?.complement;
    }

}
