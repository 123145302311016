import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BasicObject, FORM_ERROR_KEYS, PWD_RULES, REGEXS } from '@common-modules';

type conditionHandler = (control: AbstractControl) => boolean;
type validatorReturn = ValidationErrors | null;

const baseValidator = (conditionHandler: conditionHandler, key: string):
ValidatorFn => (control: AbstractControl): validatorReturn => conditionHandler(control) ? { [key]: { value: control?.value } } : null;


const tooYoungValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => {
        const splittedDate = control?.value.split('/');
        if (splittedDate.length === 3) {
            const birthDate = new Date(splittedDate[2], splittedDate[1], splittedDate[0]);
            const timeDiff = Math.abs(Date.now() - birthDate.getTime());
            const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
            return age < 16;
        }
        return false;
    };
    return baseValidator(forbidden, FORM_ERROR_KEYS.TOO_YOUNG);
};

export const tooYoungValidator: ValidatorFn = tooYoungValidatorHandler();

const searchClientValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => {
        if (REGEXS.PHONE_REGEXP.test(control.value) || REGEXS.EMAIL_BBOX_REGEXP.test(control.value)
            || REGEXS.EMAIL_REGEXP.test(control.value)) {
            return false;
        }
        return true;
    };
    return baseValidator(forbidden, FORM_ERROR_KEYS.EMAIL_BBOX);
};

export const searchClientValidator: ValidatorFn = searchClientValidatorHandler();


type charTypeChecker = (char: number) => boolean;

const isAlpha: charTypeChecker = (char: number) => char > 96 && char < 122;

const isNum: charTypeChecker = (char: number) => char > 47 && char < 58;

type followRuleType = (password: string, maxFollow: number, revert: boolean, alpha: boolean) => boolean;
const followRule: followRuleType = (password: string, maxFollow: number, revert: boolean, alpha: boolean) => {
    let nb = 1;
    password = password.toLowerCase();
    let check: number = password.charCodeAt(0);

    for (let i = 1; i < password.length; i++) {
        if (revert) {
            check++;
        } else {
            check--;
        }
        const asciiValue: number = password.charCodeAt(i);
        if (asciiValue === check &&
            ((isNum(asciiValue) && !alpha) || (isAlpha(asciiValue) && alpha))
        ) {
            nb++;
            if (nb === maxFollow) {
                return true;
            }
        } else {
            check = password.charCodeAt(i);
            nb = 1;
        }
    }
    return false;
};

const followAlphaRule = (password: string): boolean => followRule(password, PWD_RULES.ALPHA_FOLLOW_MAX, false, true) ||
followRule(password, PWD_RULES.ALPHA_FOLLOW_MAX, true, true);

const followNumRule = (password: string): boolean => followRule(password, PWD_RULES.NUM_FOLLOW_MAX, false, false) ||
followRule(password, PWD_RULES.NUM_FOLLOW_MAX, true, false);

const forbiddenCharValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => {
        const matchResult: any = control?.value.match(PWD_RULES.REJECTED_CHAR_REGEX);
        return (matchResult && matchResult[0]) ? matchResult : null;
    };
    return baseValidator(forbidden, FORM_ERROR_KEYS.FORBIDDEN_CHAR);
};

export const forbiddenCharValidator: validatorReturn = forbiddenCharValidatorHandler();

const rejectedStringValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => {
        const group = control.parent;
        if (group) {
            const identifiant = group.get('email')?.value;
            if (identifiant) {
                return control?.value.includes(identifiant);
            } else {
                return null;
            }
        }
        return null;
    };
    return baseValidator(forbidden, FORM_ERROR_KEYS.REJECTED_STRING);
};

export const rejectedStringValidator: validatorReturn = rejectedStringValidatorHandler();

const alphaFollowValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => followAlphaRule(control?.value);
    return baseValidator(forbidden, FORM_ERROR_KEYS.FOLLOW_APLHA);
};

export const alphaFollowValidator: validatorReturn = alphaFollowValidatorHandler();

const numFollowValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => followNumRule(control?.value);
    return baseValidator(forbidden, FORM_ERROR_KEYS.FOLLOW_NUMBER);
};

export const numFollowValidator: validatorReturn = numFollowValidatorHandler();


export const updatedComplexDetails: BasicObject = {
    specialCharacters: false,
    digit: false,
    uppercase: false,
    lowercase: false
};

const checkComplexityValidatorHandler = (): ValidatorFn => {
    const forbidden: conditionHandler = (control: AbstractControl) => {
        const value: string = control?.value;
        const regexList: Record<string, RegExp> = {
            uppercase: PWD_RULES.UPPERCASE,
            lowercase: PWD_RULES.LOWERCASE,
            digit: PWD_RULES.DIGIT,
            specialCharacters: PWD_RULES.SPECIAL_CHARACTERS
        };

        const validRegex: string[] = Object.keys(regexList).filter((key) => {
            updatedComplexDetails[key] = regexList[key].test(value);
            return updatedComplexDetails[key];
        });

        return validRegex.length < 3;
    };
    return baseValidator(forbidden, FORM_ERROR_KEYS.PASSWORD_COMPLEX);
};

export const checkComplexityValidator: validatorReturn = checkComplexityValidatorHandler();
