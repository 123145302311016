.hot-toast-close-btn,
.has-cursor {
    cursor: pointer;
}

.is-borderless {
    border: none !important;
}

.is-fullwidth {
    width: 100%;
}

.is-align-stretch {
    align-items: stretch;
}

.is-justified-between {
    justify-content: space-between;
}

.no-select {
    user-select: none;
}

.options .control {
    text-align: center;
}

.placeholder-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(100px, 5rem);
    justify-content: center;
    align-content: center;
    min-height: 100vh;
}

.is-rotating {
    animation: isRotating 0.75s ease 0s infinite normal forwards !important;
    transform-origin: 50% 50%;
}

@keyframes isRotating {

    0% {
        transform: rotate(0turn);
    }

    100% {
        transform: rotate(1turn);
    }
}

// @TODO: Remove once the PR for the package @ngneat angular 15 fixes is merged

@keyframes hotToastEnterAnimationNegative {
    0% {
        opacity: 0.5;
        transform: translate3d(0, -80px, 0) scale(0.6);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@keyframes hotToastEnterAnimationPositive {
    0% {
        opacity: 0.5;
        transform: translate3d(0, 80px, 0) scale(0.6);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

 @keyframes hotToastExitAnimationPositive {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, 130px, -1px) scale(0.5);
    }
}

@keyframes hotToastExitAnimationNegative {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -130px, -1px) scale(0.5);
    }
}

@for $i from 1 through 10 {
  .spacer-right-#{$i} {
    padding-right: #{$i}px;
  }
  .spacer-left-#{$i} {
    padding-right: #{$i}px;
  }
}


// Cancel default modal plugin styles here
body .ngneat-dialog-content {
    color: inherit;
    overflow: auto;
}

ngneat-dialog {
    .ngneat-close-dialog {
        justify-content: flex-end;
        margin: 0 0 1rem auto;
        position: sticky;
        top: 1rem;
        padding: 2rem;
        width: fit-content;
    }

    .ngneat-dialog-content {
        top: 7rem;
        position: absolute;
        min-height: auto !important;
        max-height: calc(100vh - 8rem); // 8rem is some rem above the height of the sticky header  
    }
}

// @TODO: End of workaround