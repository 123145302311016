@import 'variables';

@import 'angular-calendar/scss/variables';
@import 'angular-calendar/scss/angular-calendar';

.wrapper {
    padding: 0 1rem;
}

#main-content {

    .page-container {
        align-items: flex-start;
    }

    .align-ticket {
        align-self: stretch;
    }

    // Prevent disabled state but keeps the behavior
    .has-actions-disabled {

        &,
        .button {
            pointer-events: none;
        }

        .button {

            background: $grey !important;
            border-color: transparent !important;
        }
    }

    // Trilogy should avoid remvoe of this.
    .is-sr-only {
        border: none !important;
        clip: rect(0, 0, 0, 0) !important;
        height: 0.01em !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        white-space: nowrap !important;
        width: 0.01em !important;
    }

    .bytelSvg-icon {
        fill: currentColor;
    }

    .has-cursor {
        cursor: pointer;
    }

    .buttons {
        justify-content: space-evenly;
    }

    .buttons-centered {
        display: flex;
        justify-content: center;
    }

    .is-stretched {

        $padding-content: size(2rem);
        $padding-content-desktop: size(1.5rem);

        margin-left: -$padding-content;
        margin-right: -$padding-content;
        width: auto;

        @include desktop {
            margin-left: -$padding-content-desktop;
            margin-right: -$padding-content-desktop;
        }
    }

    .is-hcentered {

        &-tablet {
            @include touch() {
                justify-content: center;
            }
        }
    }

    .is-vcentered {

        @include until($tablet) {
            &-touch {
                align-self: center;
            }
        }
        @include from($tablet) {
            &-tablet {
                align-self: center;
            }
        }
        @include from($desktop) {
            &-desktop {
                align-self: center;
            }
        }
    }

    .no-min-width {
        min-width: unset;
    }

    .no-min-height {
        min-width: unset;
    }

    .is-title-clipped {

        // if @mixin() font-size should be passed as arg
        min-height: 6rem;
        max-height: 6rem; // Assume from $body-line-height: 1.5 !default; defined in _generic.scss file
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        // Ensure better compatibility
        overflow-wrap: break-word;
        word-wrap: break-word;
        // Future proof
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .wrapper {
        padding: 0 1rem;
    }

    // Order with breakpoint priority
    @for $i from 1 through 5 {

        .order-#{$i} {
            order: #{$i};

            @include until($tablet) {
                &-touch {
                    order: #{$i};
                }
            }

            @include from($tablet) {
                &-tablet {
                    order: #{$i};
                }
            }

            @include from($desktop) {
                &-desktop {
                    order: #{$i};
                }
            }

            @include from($desktop) {
                &-desktop {
                    order: #{$i};
                }
            }
        }
    }

    .step-container {
        margin-bottom: 1.4rem;
    }

    #checkout-ticket {

        position: relative;
        z-index: 99;
    }

    .is-disabled-block {

        cursor: not-allowed;
        filter: grayscale(1);
        pointer-events: none;
    }

    .control.has-dynamic-placeholder .input:placeholder-shown:focus {
        padding-top: 0.4em;                  // .375em;
    }
    .control.has-dynamic-placeholder .input:focus {
        padding-top: 1.28em;                 //1.2em;
    }
    .control.has-icons-right .input:focus {
        padding-right: 5.866666666666669rem; //5.5rem;
    }

    .control.has-icons-right .input:focus, .control.has-icons-right .select select:focus {
        padding-right: 6.400000000000002rem;    // 6rem
    }

    .input:focus, .textarea:focus {
        padding-left: 1.6rem; //1.5rem;
    }
    .icon-and-text.is-alternate {
        align-items: flex-start; // présent et recommandé quand plusieurs lignes dans la doc mais pas dans le css ?
    }
    .icon.is-small {
        min-width: 3rem; // pour une raison inconnue les checks de l'assurance sont plus petits sinon
    }

    .options .field {
        margin-left: 2rem;  // pour la symétrie
        min-width: 12rem;   // pour être cohérent sinon les éléments ne s'accordent pas
    }

    .is-descrp-clipped {

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        // Ensure better compatibility
        overflow-wrap: break-word;
        word-wrap: break-word;
        // Future proof
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }

    .is-text-clipped {
        text-overflow: ellipsis;
        white-space: normal;
        // Ensure better compatibility
        overflow-wrap: break-word;
        word-wrap: break-word;
        // Future proof
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    :not(.is-tri) {
        @keyframes spinAround {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }


    // There is a need to encapsulated namespaces' keyframes with the class instead of including them in @keyframes
    @keyframes spinAroundFix {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }

    .button {

        &.is-loading {
            &::after {
                animation: spinAroundFix 500ms infinite linear;
            }
        }
    }

    .has-border-warning {
        border: 5px solid $warning
    }

    .cal-month-view .cal-cell-row {

        .cal-cell-top {
            min-height: 65px;
            position: relative;

            @include from($tablet) {
                padding: 5px;
            }
        }

        .mwl-calendar-week-view-hour-segment {
            display: unset;
        }

        .cal-day-view {
            .mwl-calendar-week-view-header {
                display: inherit;
            }
        }

        .cal-day-number {

            @include until($tablet) {
                margin-right: 0.3em;
            }

            margin-right: 20px;

            &.active {
                opacity: 1;
            }
        }

        .cal-day-cell {

            min-height: unset;

            &.cal-today {
                background-color: #109db947;
                font-size: inherit;

                .cal-day-number {
                    font-size: 1.2em;
                    font-weight: bold;
                }
            }

            &.cal-day-selected {
                background-color: #25465f;

                .cal-day-number {
                    color: $white;
                }

                .cal-day-badge {
                    background-color: $white;
                    color: #25465f;
                }

                &.cal-cell:hover {
                    background-color: #25465f;
                }
            }
        }

        .cal-day-badge {

            // Cancel
            vertical-align: unset;
            min-width: none;

            @include until($tablet) {

                bottom: 2px;
                margin: 0 auto;
                left: 0;
                top: auto;
                right: 0;
                height: 35%;
            }

            align-items: center;
            display: flex;
            justify-content: center;
            max-height: 25px;
            max-width: 25px;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            border-radius: 100%;
            position: absolute;
            bottom: auto;
            left: auto;
            right: 3px;
            top: 2px;
            margin: auto;
            padding: 5px;
            height: 50%;
            width: 50%;
            background-color: #25465f;
            color: $white;
        }
    }
    .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
        color: #25465f !important;
    }
}
