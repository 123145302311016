import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

@Directive({
    selector: '[tlvFormIcon]',
    standalone: false
})
export class FormIconDirective implements OnInit {

    @Input() tlvFormIcon: AbstractControl;

    private _touchedChanges$ = new Subject<boolean>();

    constructor(public el: ElementRef) {}

    public ngOnInit(): void {

        const prevMarkAsTouched = this.tlvFormIcon.markAsTouched;

        const prevMarkAsUntouched = this.tlvFormIcon.markAsUntouched;
        this.tlvFormIcon.markAsTouched = (...args: ArgumentsType<AbstractControl['markAsTouched']>): void => {
            prevMarkAsTouched.bind(this.tlvFormIcon)(...args);
            this._touchedChanges$.next(true);
        };
        this.tlvFormIcon.markAsUntouched = (...args: ArgumentsType<AbstractControl['markAsUntouched']>): void => {
            prevMarkAsUntouched.bind(this.tlvFormIcon)(...args);
            this._touchedChanges$.next(false);
        };
        merge(this.tlvFormIcon.statusChanges.pipe(startWith('invalid')),this._touchedChanges$)
            .subscribe(()=>{
                this.el.nativeElement.classList.remove('is-searching');
                this.el.nativeElement.classList.remove('tri-check-circle');
                this.el.nativeElement.classList.remove('tri-exclamation-circle');
                if (this.tlvFormIcon.disabled || !this.tlvFormIcon.touched){return;}
                switch (this.tlvFormIcon.status){
                    case 'PENDING' :
                        this.el.nativeElement.classList.add('is-searching');
                        break;
                    case 'INVALID':
                        this.el.nativeElement.classList.add('tri-exclamation-circle');
                        break;
                    case 'DISABLED':
                        break;
                    case 'VALID':
                        this.el.nativeElement.classList.add('tri-check-circle');
                        break;
                }
            });
        this._touchedChanges$.next(this.tlvFormIcon.touched);
    }

}
