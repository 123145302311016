import { Component, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Fai, Plan, Product, ProductFactory, QUOTE_CONTEXTS, Sim } from '@bytel/bytel-sales';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SimService } from '@services/checkout/sim.service';
import { Observable, ReplaySubject, Subscription, catchError, map, mergeMap, of, tap } from 'rxjs';
import { SimCardImcompatibilityModalComponent } from './modals/sim-card-imcompatibility-modal/sim-card-imcompatibility-modal.component';


interface DisplayableSim {
    gencode: string;
    name: string;
    isPrefered: boolean;
    isCompatibleWithDevice: boolean;
}

@Component({
    selector: 'tlv-sim-card-choice',
    templateUrl: './sim-card-choice.component.html',
    styleUrls: ['./sim-card-choice.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimCardChoiceComponent),
            multi: true
        }
    ],
    standalone: false
})
export class SimCardChoiceComponent implements ControlValueAccessor, OnDestroy {

    public simChoiceForm: FormGroup = new FormGroup({
        simSelection: new FormControl(true, [Validators.required])
    }, []);

    public displayableSims: DisplayableSim[] = [];
    public isLoading = false;
    public hasError = false;

    private _simRestoredFromCart: Sim;
    private _cartModel = this.cartTeleSalesService.cartModel;
    private _subscriptions: Subscription[] = [];
    private _currentQuoteIndex: number;
    private _updatedSimsWithPrices: Sim[] = [];
    private _onChangeSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private simService: SimService,
        private cartTeleSalesService: CartTeleSalesService,
        private dialogService: DialogService
    ) {
        this.isLoading = true;
        this._currentQuoteIndex = this.cartTeleSalesService.cartModel.currentQuoteIndex;
        this._simRestoredFromCart = this._cartModel.getQuote(this._currentQuoteIndex).getProductByType('Sim');
        this._initSimsPrices();
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public writeValue(): void { return null; }

    public registerOnChange(fn: any): void {
        this._onChangeSubject.subscribe(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public getSimByGencode(gencode: string): Sim {
        return this._updatedSimsWithPrices.find((sim) => sim.data.gencode === gencode);
    }

    private onTouched: () => void = (): void => null;

    private _initSimsPrices(): void {
        if (this.cartTeleSalesService.isQuoteMixed()){
            this._currentQuoteIndex = this._cartModel.quotes.findIndex(quote => quote.context.id === QUOTE_CONTEXTS.ACQUISITION);
        }
        const plan: Plan = this._cartModel.getQuote(this._currentQuoteIndex).getProductByType('Plan');
        if (plan && !ProductFactory.Is(plan, Fai, true)) {
            const simUpdateObs$ = this._simRestoredFromCart ?
                this._removeProduct(this._simRestoredFromCart, this._currentQuoteIndex) : null;
            this._subscriptions.push(of(simUpdateObs$).pipe(
                mergeMap(() => this.simService.updateSimProduct(this._cartModel, plan, this._currentQuoteIndex)),
                catchError((error) => {
                    this.isLoading = false;
                    this.hasError = true;
                    console.error(error);
                    return of(null);
                }),
                mergeMap(() => this._updateSimsPrices())
            ).subscribe());
        }
    }

    private _updateSimsPrices(): Observable<Sim[]> {
        if (!this.simService?.availableSims) {
            this.hasError = true;
            this.isLoading = false;
            return of(null);
        }

        const sims: Sim[] = this.simService.availableSims[this._currentQuoteIndex]?.associatedSims;
        if (!sims?.length) {
            this.isLoading = false;
            this.hasError = true;
            return of(null);
        }
        return of(null).pipe(
            map(() => {
                this._updatedSimsWithPrices = sims;
                return this._updatedSimsWithPrices;
            }),
            mergeMap((products) => this._simRestoredFromCart ? this._updateSimPricesInCart().pipe(map(() => products)) : of(products)),
            tap(() => {
                this._setDisplayableSims();
                this._addPreferredSim();
                this._onSimCardChoiceChange();
            })
        );
    }

    private _updateSimPricesInCart(): Observable<Product> {
        const simWithUpdatedPrices: Sim = this._updatedSimsWithPrices
            .find(sim => sim.data.gencode === this._simRestoredFromCart.data.gencode);
        return this._addSimToCart(simWithUpdatedPrices, this._currentQuoteIndex);
    }

    private _onSimCardChoiceChange(): void {
        this.simChoiceForm.get('simSelection').valueChanges.subscribe((gencode: string) => {
            this.isLoading = true;
            const simIncart: DisplayableSim = this.displayableSims.find((sim) => sim.gencode !== gencode);
            const simToAddInCart: DisplayableSim = this._getAvailableSimByGencode(gencode);
            if ('isCompatibleWithDevice' in simToAddInCart && !simToAddInCart.isCompatibleWithDevice) {
                this.isLoading = false;
                const dialogRef = this.dialogService.open(SimCardImcompatibilityModalComponent, {
                    size: 'md',
                    enableClose: false,
                    data: {
                        simAlreadyInCartGencode: simIncart?.gencode,
                        newSimGencode: this._getAvailableSimByGencode(gencode)?.gencode,
                        quoteIndex: this._currentQuoteIndex,
                        fnRemoveAndUpdateSimInCart: this._removeAndUpdateSimInCart.bind(this),
                    }
                });
                this._subscriptions.push(dialogRef.afterClosed$.subscribe((result) => {
                    if (!result?.confirm) {
                        this.simChoiceForm.get('simSelection').setValue(simIncart.gencode, { emitEvent: false });
                    }
                    this.isLoading = false;
                    this.simService.setSelectedSim(simToAddInCart.gencode);
                    this._onChangeSubject.next(true);
                    this.onTouched();
                }));
            } else {
                this._subscriptions.push(this._removeAndUpdateSimInCart(simIncart.gencode, gencode).subscribe(
                    {
                        next: () => {
                            this.isLoading = false;
                            this.simService.setSelectedSim(simToAddInCart.gencode);
                            this._onChangeSubject.next(true);
                            this.onTouched();
                        },
                        error: () => {
                            this.isLoading = false;
                        }
                    }
                ));
            }
        });
    }

    private _addPreferredSim(): void {
        let preferredSim = this.displayableSims.find((sim) => sim.isPrefered);
        if (!preferredSim && this.displayableSims.length === 1) {
            preferredSim = this.displayableSims[0];
            this.getSimByGencode(this.displayableSims[0].gencode);
        }

        if (this._simRestoredFromCart) {
            preferredSim = this.displayableSims.find((sim) => sim.gencode === this._simRestoredFromCart.data.gencode);
        }

        const simToAdd = this._updatedSimsWithPrices.find((sim) => sim.data.gencode === preferredSim.gencode);
        this._subscriptions.push(this._addSimToCart(simToAdd, this._currentQuoteIndex).subscribe({
            next: (sim) => {
                this.simChoiceForm.get('simSelection').setValue(sim.gencode, { emitEvent: false });
                this.simChoiceForm.get('simSelection').markAsDirty();
            },
            error: (error) => {
                console.warn(error);
                this.isLoading = false;
            },
            complete: () => {
                this.isLoading = false;
                this._onChangeSubject.next(true);
            }
        }));
    }

    private _addSimToCart(product: Product, quoteIndex: number): Observable<Product> {
        return this.cartTeleSalesService.addProduct(product, quoteIndex, true);
    }

    private _removeProduct(product: Product, quoteIndex: number): Observable<Product> {
        return this.cartTeleSalesService.removeProductsByGencode(product.gencode, quoteIndex).pipe(map((products) => products.shift()));
    }

    private _setDisplayableSims(): void {
        this.displayableSims = this.simService.availableSims[this._currentQuoteIndex].sims.map(sim => ({
            gencode: sim.gencode,
            name: sim.name,
            isPrefered: sim.isPrefered,
            isCompatibleWithDevice: sim.isCompatibleWithDevice
        }));
        if (this.displayableSims.length === 1 && !this.displayableSims[0]?.isCompatibleWithDevice) {
            this.dialogService.open(SimCardImcompatibilityModalComponent, {
                size: 'md',
                enableClose: true,
                data: {
                    simAlreadyInCartGencode: this.displayableSims[0]?.gencode,
                    newSimGencode: this.displayableSims[0]?.gencode,
                    quoteIndex: this._currentQuoteIndex,
                    fnRemoveAndUpdateSimInCart: this._removeAndUpdateSimInCart.bind(this),
                }
            });
        }
    }

    private _removeAndUpdateSimInCart(oldGencode: string, gencode: string): Observable<Product> {
        return this.cartTeleSalesService.removeProductsByGencode(oldGencode, this._currentQuoteIndex).pipe(
            mergeMap(() => this._addSimToCart(this.getSimByGencode(gencode), this._currentQuoteIndex))
        );
    }

    private _getAvailableSimByGencode(gencode: string = this.simChoiceForm.get('sim').value): DisplayableSim {
        return this.displayableSims.find((sim) => sim.gencode === gencode);
    }
}
