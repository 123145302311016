<section class="has-background-white step-container" data-cy="customer-info">
  <div class="box is-clipped">
    <div class="columns is-mobile is-multiline is-marginless">
      <div class="column is-flex is-paddingless">
        <span class="icon is-medium">
          <i class="tri-identity"></i>
        </span>
        <span class="is-size-3-tablet is-size-4 has-text-weight-bold is-flex is-vcentered" bytelTranslate="panier.coordonnees.titre">
          Coordonnées
        </span>
      </div>
    </div>
    <div class="is-divider is-stretched"></div>
    @if (isValidatingAutomaticStep) {
      <div class="column is-8 is-offset-2">
        <div class="has-body is-small notification">
          <div class="icon">
            <i class="tri-infos-circle" aria-hidden="true"></i>
          </div>
          <div class="body">
            <p class="title">Chargement des informations clients, un moment...</p>
          </div>
        </div>
      </div>
    }
    <form [formGroup]="form" class="column is-8 is-offset-2" id="signup-form">
      <div class="field">
        <div class="control has-dynamic-placeholder has-icons-right">
          <input tlvFormClass [tlvFormClassErrorControl]="form.get('email')"
            class="input" formControlName="email" id="email" maxlength="100" placeholder="Email *"
            required type="email" data-cy="email" CustomFormControl>
          <label for="email" bytelTranslate="panier.coordonnees.email.label">Email</label>
          <span class="icon is-small">
            <i [tlvFormIcon]="form.get('email')"></i>
          </span>
        </div>
        @if (customerDetails?.idPerson && !customerDetails?.isUpdatable && form.get('email').dirty) {
          <div
            class="email-info has-text-grey-dark">
            <span class="icon is-small"><i class="tri-question-circle"></i></span>
            <span data-cy="customer-email-order-only-notification" class="text is-size-8" bytelTranslate="panier.coordonnees.email.information">La modification de votre adresse email sera prise en compte uniquement dans le
              cadre de cette commande. Si vous souhaitez la modifier de façon permanente, rendez-vous dans
              "Vos&nbsp;infos&nbsp;Perso" de votre Espace&nbsp;Client, dès votre commande finalisée.
            </span>
          </div>
        }
        @if (form.get('email').invalid && form.get('email').touched) {
          <ul class="help is-danger">
            @if (form.get('email').errors.badEmail) {
              <li data-cy="bad-email">L’adresse e-mail renseignée n’existe pas, veuillez saisir une nouvelle adresse e-mail</li>
            }
            @if (form.get('email').errors.required) {
              <li data-cy="required-email">Adresse e-mail est obligatoire</li>
            }
            @if (form.get('email').errors.pattern) {
              <li data-cy="bad-pattern-email">Cet e-mail semble invalide</li>
            }
            @if (form.get('email').errors.domainBbox) {
              <li data-cy="bbox-email">Il n’est pas possible de créer un compte en &#64;bbox.fr</li>
            }
            @if (form.get('email').errors.alreadyCustomer) {
              <li data-cy="exist-email">L’adresse e-mail est déjà associée à un utilisateur, merci de saisir à nouveau l'email</li>
            }
            @if (form.get('email').errors.knownCustomerEmail) {
              <li data-cy="known-customer-email">Impossible de créer le client avec cette adresse mail</li>
            }
          </ul>
        }
      </div>
      <div class="is-divider"></div>
      <div>

        <div class="options buttons is-centered columns is-variable is-3">
          <div class="column is-6">
            <div class="field is-marginless">
              <div class="control has-text-centered">
                <input [value]="userTitles.missis" formControlName="gender" id="titleW"
                  name="gender" required type="radio" data-cy="gender-w" CustomFormControl>
                <label [attr.disabled]="form.get('gender').disabled && form.get('gender').value !== userTitles.missis ? true : null"
                  [ngClass]="form.get('gender').value === userTitles.missis ? 'is-active' : null"
                  for="titleW" bytelTranslate="panier.coordonnees.civilite.mme">
                  Madame
                </label>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field is-marginless">
              <div class="control has-text-centered">
                <input [value]="userTitles.mister" formControlName="gender" id="titleM"
                  name="gender"
                  required type="radio" data-cy="gender-m" CustomFormControl>
                <label [attr.disabled]="form.get('gender').disabled && form.get('gender').value !== userTitles.mister ? true : null"
                  [ngClass]="form.get('gender').value === userTitles.mister ? 'is-active' : null"
                for="titleM" bytelTranslate="panier.coordonnees.civilite.m">Monsieur</label>
              </div>
            </div>
          </div>
        </div>

        @if (form.get('gender').invalid && form.get('gender').touched) {
          <ul
            class="help is-danger column is-6 title-error">
            @if (form.get('gender').errors.required) {
              <li bytelTranslate="panier.coordonnees.civilite.erreur">Civilité est obligatoire</li>
            }
          </ul>
        }
      </div>
      <div class="field">
        <div class="control has-dynamic-placeholder has-icons-right">
          <input tlvFormClass [tlvFormClassErrorControl]="form.get('lastname')" class="input"
            formControlName="lastname" id="lastname"
            maxlength="32" minlength="2"
            placeholder="Nom *"
            required type="text" data-cy="lastname" CustomFormControl>
          <label for="lastname" bytelTranslate="panier.coordonnees.nom.label">Nom</label>
          <span class="icon is-small">
            <i [tlvFormIcon]="form.get('lastname')"></i>
          </span>
        </div>
        @if (form.get('lastname').invalid && form.get('lastname').touched) {
          <ul
            class="help is-danger">
            @if (form.get('lastname').errors.required) {
              <li bytelTranslate="panier.coordonnees.nom.erreurs.requis">Nom est obligatoire</li>
            }
            @if (form.get('lastname').errors.minlength ||form.get('lastname').errors.maxlength) {
              <li bytelTranslate="panier.coordonnees.nom.erreurs.longueur">Le nom doit contenir de 2 à 32
                caractères alphabétiques (accents, tirets, espace et apostrophes autorisés)
              </li>
            }
            @if (form.get('lastname').errors.pattern) {
              <li bytelTranslate="panier.coordonnees.nom.erreurs.invalide">Nom invalide</li>
            }
          </ul>
        }
      </div>
      <div class="field">
        <div class="control has-dynamic-placeholder has-icons-right">
          <input tlvFormClass [tlvFormClassErrorControl]="form.get('firstname')" class="input"
            formControlName="firstname" id="firstname"
            maxlength="32" minlength="2"
            placeholder="Prénom *"
            required type="text" data-cy="firstname" CustomFormControl>
          <label for="firstname" bytelTranslate="panier.coordonnees.prenom.label">Prénom</label>
          <span class="icon is-small">
            <i [tlvFormIcon]="form.get('firstname')"></i>
          </span>
        </div>
        @if (form.get('firstname').invalid && form.get('firstname').touched) {
          <ul
            class="help is-danger">
            @if (form.get('firstname').errors.required) {
              <li bytelTranslate="panier.coordonnees.prenom.erreurs.requis">Prénom est obligatoire</li>
            }
            @if (form.get('firstname').errors.minlength ||form.get('firstname').errors.maxlength) {
              <li bytelTranslate="panier.coordonnees.prenom.erreurs.longueur">Le prénom doit contenir de 2 à
                32 caractères alphabétiques (accents, tirets, espace et apostrophes autorisés)
              </li>
            }
            @if (form.get('firstname').errors.pattern) {
              <li bytelTranslate="panier.coordonnees.prenom.erreurs.invalide">Prénom invalide</li>
            }
          </ul>
        }
      </div>
      <div class="field">
        <label class="is-sr-only" for="birthDepartment" bytelTranslate="panier.coordonnees.departement_naissance.label">Département de naissance</label>
        <div class="select">
          <select tlvFormClass [tlvFormClassErrorControl]="form.get('birthDepartment')"
            formControlName="birthDepartment" data-cy="birth-department" CustomFormControl
            id="birthDepartment" placeholder="-- Département de naissance --">
            <option value bytelTranslate="panier.coordonnees.departement_naissance.option">-- Département de naissance --</option>
            @for (region of regions; track region) {
              <option value="{{ region.code }}">
                {{ region.code }} - {{ region.label }}
              </option>
            }
          </select>
        </div>
        @if (form.get('birthDepartment').invalid && form.get('birthDepartment').touched) {
          <ul
            class="help is-danger">
            @if (form.get('birthDepartment').errors.required) {
              <li bytelTranslate="panier.coordonnees.departement_naissance.erreur">Département de naissance obligatoire</li>
            }
          </ul>
        }
      </div>
      <div class="field columns is-multiline">
        <div class="column is-6-desktop">
          <div class="field">
            <div class="control has-dynamic-placeholder has-icons-right">
              <input tlvFormClass [tlvFormClassErrorControl]="form.get('birthDepartment')"
                class="input" [clearIfNotMatch]="true" formControlName="birthDate"
                id="birthDate" data-cy="birth-date" [dropSpecialCharacters]="false"
                mask="d0/M0/0000" placeholder="Date de naissance (jj/mm/aaaa)*"
                required type="text" CustomFormControl>
              <label for="birthDate" bytelTranslate="panier.coordonnees.numero_telephone.label">Date de naissance (jj/mm/aaaa)*</label>
              <span class="icon is-small">
                <i [tlvFormIcon]="form.get('birthDate')"></i>
              </span>
            </div>
            @if (form.get('birthDate').invalid && form.get('birthDate').touched) {
              <ul
                class="help is-danger">
                @if (form.get('birthDate').errors.required) {
                  <li>Date de naissance obligatoire</li>
                }
                @if (form.get('birthDate').errors.pattern) {
                  <li>Le format de la date est invalide JJ/MM/AAAA</li>
                }
                @if (form.get('birthDate').errors.tooYoung && !form.get('birthDate').errors.pattern) {
                  <li>Vous devez avoir 16 ans ou plus pour souscrire une ligne</li>
                }
              </ul>
            }
            @if (mustConfirmEmancipatedMinor) {
              <div class="emancipated-minor-checkbox control">
                <input data-cy="emancipated-minor-checkbox" class="is-checkradio" id="emancipated-minor" type="checkbox"
                  formControlName="emancipatedMinor">
                <label data-cy="emancipated-minor-label" class="is-size-7 has-text-danger" for="emancipated-minor">
                  Le client confirme être un mineur émancipé
                </label>
              </div>
            }
          </div>
        </div>
        <div class="column is-6-desktop">
          <div class="field">
            <div class="control has-dynamic-placeholder has-icons-right">
              <input tlvFormClass [tlvFormClassErrorControl]="form.get('phone')"
                class="input" formControlName="phone"
                id="phone" data-cy="phone"
                mask="00 00 00 00 00" placeholder="Numéro de téléphone mobile *"
                required type="text" CustomFormControl>
              <label for="phone" bytelTranslate="panier.coordonnees.numero_telephone.label">Numéro de téléphone mobile</label>
              <span class="icon is-small">
                <i [tlvFormIcon]="form.get('phone')"></i>
              </span>
            </div>
            <label class="help" for="phone" bytelTranslate="panier.coordonnees.numero_telephone.aide">Obligatoire pour l'envoi de votre suivi de commande par SMS</label>
            @if (form.get('phone').invalid && form.get('phone').touched) {
              <ul
                class="help is-danger phone-error">
                @if (form.get('phone').errors.required || form.get('phone').errors.pattern || form.get('phone').errors.minLength || form.get('phone').errors.maxlength) {
                  <li>
                    Saisir un <abbr title="Numéro">N°</abbr> de téléphone mobile
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      </div>
      <div class="field">
        <div class="buttons has-text-centered">
          @if (mock) {
            <button class="button is-info" type="button" (click)="prefillFields()">Remplir</button>
          }
          <button data-cy="customer-validate-btn" class="is-primary button" (click)="submit()" [disabled]="isLoading || !form.valid">
            {{isLoading ? 'Traitement en cours...' : 'Valider'}}
          </button>
        </div>
      </div>
      <p bytelTranslate="panier.consentement.champ_obligatoire">* Champ obligatoire pour terminer votre achat</p>
    </form>
  </div>
</section>


