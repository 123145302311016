import { Injectable } from '@angular/core';

import { NotificationModel } from '@models/notification.model';
import { NotificationsRepository } from '@repositories/notifications.repository';
import { catchError, forkJoin, map, Observable, of, ReplaySubject, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    public notifications$: Observable<NotificationModel[]>;
    public notifications: NotificationModel[] = [];

    private _notificationsSubject$ = new ReplaySubject<NotificationModel[]>(1);

    constructor(
        private readonly notificationRepository: NotificationsRepository
    ){
        this.notifications$ = this._notificationsSubject$.asObservable();
    }

    public resolve(): Observable<NotificationModel[]> {
        return this.getNotifications();
    }

    public getNotifications(): Observable<NotificationModel[]> {
        return this.notificationRepository.getNotifications().pipe(
            catchError(()=>of([])),
            tap((data: NotificationModel[])=>{
                this.notifications = data;
                this._notificationsSubject$.next(this.notifications);
            })
        );
    }

    public updateNotificationsReadersList(notificationIds: string[]): Observable<boolean> {
        if (!notificationIds.length) {
            return of(false);
        }
        return forkJoin(
            notificationIds.map(notifId => this.notificationRepository.updateNotificationsReaders(notifId))
        ).pipe(map(()=>true));
    }

}
