<form [formGroup]="addressForm" class="column is-9-desktop is-12">
  <div class="columns is-multiline is-vcentered">
    <div class="column is-3">
      <div class="field">
        <div class="control has-dynamic-placeholder">
          <input [data-cy]="'manual-address-street-number'" class="input" id="streetNumber" formControlName="streetNumber" placeholder="N°" type="text" maxlength="10">
          <label for="streetNumber" bytelTranslate="panier.livraison.adresse_manuelle.numero">N°</label>
        </div>
        @if (addressForm.get('streetNumber').touched && addressForm.get('streetNumber').errors) {
          <p [data-cy]="'manual-address-street-number-error'" class="help is-danger">Numéro erroné</p>
        }
      </div>
    </div>
    <div class="column is-9">
      <div class="field">
        <div class="control has-dynamic-placeholder">
          <input [data-cy]="'manual-address-street'" class="input" id="street" formControlName="street" placeholder="Voie" type="text" maxlength="34">
          <label for="street" bytelTranslate="panier.livraison.adresse_manuelle.voie">Voie</label>
        </div>
        @if (addressForm.get('street').touched && addressForm.get('street').errors) {
          <p [data-cy]="'manual-address-street-error'" class="help is-danger">Voie requise</p>
        }
      </div>
    </div>
    <div class="column is-3">
      <div class="field">
        <div class="control has-dynamic-placeholder">
          <input [data-cy]="'manual-address-postal-code'" class="input" id="postalCode" formControlName="postalCode" placeholder="Code postal" type="text" maxlength="5">
          <label for="postalCode">Code postal</label>
        </div>
        @if (addressForm.get('postalCode').touched && addressForm.get('postalCode').errors) {
          <p [data-cy]="'manual-address-postal-code-error'" class="help is-danger">
            {{addressForm.get('codePostal').errors.pattern ? 'Zip erroné' : 'Zip required'}}
          </p>
        }
      </div>
    </div>
    <div class="column is-9">
      <div class="field">
        <div class="control has-dynamic-placeholder">
          <input [data-cy]="'manual-address-city'" class="input" id="city" formControlName="city" placeholder="Ville" type="text" maxlength="34">
          <label for="city">Ville</label>
        </div>
        @if (addressForm.get('city').touched && addressForm.get('city').errors) {
          <p [data-cy]="'manual-address-city-error'" class="help is-danger">Ville requise</p>
        }
      </div>
    </div>
    <div class="column is-12">
      <div class="field">
        <div class="control has-dynamic-placeholder">
          <input [data-cy]="'manual-address-complement'" class="input" id="complement" formControlName="complement" placeholder="Complément" type="text" maxlength="35">
          <label for="complement" bytelTranslate="panier.livraison.adresse_manuelle.complement">Complément</label>
        </div>
        @if (addressForm.get('complement').errors) {
          <p [data-cy]="'manual-address-complement-error'" class="help is-danger">Champ invalide, caractères incorrectes.</p>
        }
      </div>
    </div>
  </div>
</form>
