import { Injectable } from '@angular/core';
import { ICommercialProposal } from '@interfaces/commercial-proposal.interface';
import { IDownloadLinkUniversalModel } from '@interfaces/download-universal-link.interface';
import { IDocumentResponse } from '@interfaces/search-document.interface';
import { CommercialProposalRepository } from '@repositories/commercialProposal.repository';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommercialProposalService {

    constructor(private commercialProposalRepo: CommercialProposalRepository) { }

    public getDocuments(idPropositionCommerciale: string): Observable<IDocumentResponse> {
        return this.commercialProposalRepo.getDocument(idPropositionCommerciale);
    }

    public getUniversalDownloadLink(idDocument: string): Observable<IDownloadLinkUniversalModel> {
        return this.commercialProposalRepo.getUniversalDownloadLink(idDocument);
    }

    public getProposalOrder(idPerson: string): Observable<ICommercialProposal[]> {
        return this.commercialProposalRepo.getProposalOrder(idPerson);
    }

    public getProposalOrderProspect(email: string, idProspect?: string): Observable<ICommercialProposal[]> {
        return this.commercialProposalRepo.getProposalOrderProspect(email, idProspect);
    }
}
