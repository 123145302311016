import { ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl } from '@angular/forms';
import { REGEXS } from '@common-modules';
import {
    Plan,
    PromotionsModel,
    PromotionsService,
    QUOTE_CONTEXTS, QuoteContextAcquisitionModel,
} from '@bytel/bytel-sales';
import { PortabilityModel } from '@models/cart/portability.model';
import { PortaValidationModel } from '@models/validators/porta-validation.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { ValidatorsService } from '@services/validators.service';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import bind from '../../../../../helper/decorators/bind';


@Component({
    selector: 'tlv-portability',
    templateUrl: './portability.component.html',
    styleUrls: ['./portability.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PortabilityComponent),
            multi: true
        }
    ],
    standalone: false
})
export class PortabilityComponent implements ControlValueAccessor, OnDestroy, OnInit {

    public portabilityForm: FormGroup = new FormGroup({
        checked : new FormControl(true, [Validators.required]),
        phoneNumber: new FormControl('',[
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(10),
            Validators.pattern(REGEXS.MOBILE_REGEXP)

        ]),
        codeRio: new FormControl('',[
            Validators.required,
            Validators.maxLength(12),
            Validators.minLength(12),
            Validators.pattern(new RegExp(/^[a-z0-9+]{12}$/, 'i'))
        ])
    },[]);
    public portaPromo;
    public messagePortaPromo: string;
    public plan: Plan;
    public isSubmitted = false;
    public formValidatorLoading = false;
    private _subscriptions: Subscription[] = [];
    private _onChangeSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private validatorsService: ValidatorsService,
        private portabilityService: PortabilityService,
        private cdr: ChangeDetectorRef
    ) {
        this._loadPortabilityData();
        this._subscriptions.push(
            this.portabilityForm.statusChanges.subscribe(this._submit),
            this.portabilityForm.get('checked').valueChanges.subscribe(this._enableDisableInput)
        );
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public ngOnInit(): void {
        this._loadPortabilityData();
    }

    public writeValue(): void { return null; }

    public registerOnChange(fn: any): void {
        this._onChangeSubject.subscribe(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private _loadPortabilityData(): void {
        this.plan = this.cartTeleSalesService.cartModel.getQuote().getProductByType(Plan);
        const allPlanPromos: PromotionsModel = PromotionsService.GetPromotionsForProduct(
            this.plan, this.cartTeleSalesService.cartModel.promotions);
        this.portaPromo = allPlanPromos.incitation.find(
            (promo) => !!(promo.message.portability_yes || promo.message.portability_non)
        );
        // fix lorsque l'on fait des alle retour avec une porta la promo passe de incitation a automatic
        if (!this.portaPromo) {
            this.portaPromo = allPlanPromos.automatic.find(
                (promo) => !!(promo.message.portability_yes || promo.message.portability_non)
            );
        }
        this.messagePortaPromo = allPlanPromos.automatic.find(
            (promo) => !!promo.message.portability_other
        )?.message.portability_other;
        const quoteIndex = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        const portabilityData: PortabilityModel = this.portabilityService.getPortabilityFromCartModel(quoteIndex);
        if (portabilityData) {
            this.portabilityForm.setValue({
                codeRio: portabilityData.rioCode,
                phoneNumber: portabilityData.phoneNumber,
                checked: !!portabilityData?.phoneNumber
            });
        } else {
            this.portabilityForm.get('checked').setValue(false);
        }
    }

    @bind
    private _enableDisableInput(checked: boolean): void {
        if (checked) {
            if (this.portabilityForm.get('codeRio').disable && this.portabilityForm.get('phoneNumber').disable) {
                this.portabilityForm.enable({emitEvent:false});
                this.cartTeleSalesService.cartModel.getQuote().getProductByType(Plan).portabilityEnable = true;
                this._updateAndRefreshCart(checked);
            }
        } else {
            if (this.portabilityForm.get('codeRio').enabled && this.portabilityForm.get('phoneNumber').enabled) {
                this.portabilityForm.get('codeRio').reset();
                this.cartTeleSalesService.cartModel.getQuote().getProductByType(Plan).portabilityEnable = false;
                this._updateAndRefreshCart(checked);
                this.portabilityForm.get('phoneNumber').reset();
                this.portabilityForm.get('codeRio').disable({emitEvent:false});
                this.portabilityForm.get('phoneNumber').disable({emitEvent:false});
            }
        }
    }

    private _updateAndRefreshCart(checked: boolean): void {

        // The update is already done is submit which is triggered on form changes
        if (!checked || !this.portabilityService.portability) {
            return;
        }

        const { phoneNumber = '', rioCode = '' } = this.portabilityService?.portability || {};

        if (rioCode !== this.portabilityForm.get('codeRio').value ||
            phoneNumber !== this.portabilityForm.get('phoneNumber').value) {
            this.cartTeleSalesService.refresh();
        }
    }

    @bind
    private _submit(status: string): void {
        this._onChangeSubject.next(status === 'VALID' ? this.portabilityForm.get('checked').value : null);
        if (status === 'VALID' && this.portabilityForm.get('checked').value){
            this.formValidatorLoading = true;
            this.validatorsService.portaValidation(
                this.portabilityForm.get('phoneNumber').value,
                this.portabilityForm.get('codeRio').value
            ).pipe(
                map((data: PortaValidationModel) => {
                    this.portabilityService.update(new PortabilityModel({
                        ...data,
                        lineType: QUOTE_CONTEXTS.ACQUISITION
                    }));
                    return true;
                }),
                catchError((data) => {
                    if (this.portabilityForm.get('codeRio').enabled) {
                        this.portabilityForm.setErrors({apiError: data?.error?.message});
                        return of(null);
                    }
                }),
                finalize(() => this.formValidatorLoading = false)
            ).subscribe(()=>{
                this.cartTeleSalesService.setProductId(this.plan.gencode, null);
                this.cartTeleSalesService.refresh();
            });
        } else if (status === 'VALID'){
            this.portabilityService.update(null);
            this.cartTeleSalesService.setProductId(this.plan.gencode, null);
            this.cartTeleSalesService.refresh();
        }
        this.onTouched();
        this.cdr.detectChanges();
    }

    private onTouched: () => void = (): void => null;
}
