import { Injectable } from '@angular/core';
import { Fai, QuoteContextFaiModel } from '@bytel/bytel-sales';
import { IFaiAppointmentParams, IFaiAppointmentUpdatedParams } from '@interfaces/api/appointment/appointmentInput';
import { COLORS_APPOINTMENT, IAppointmentForm } from '@interfaces/appointment.interface';
import { AddressModel } from '@models/cart/address.model';
import { AppointmentModel } from '@models/cart/appointment.model';
import { DeliveryAppointmentModel } from '@models/cart/delivery-appointment.model';
import { SlotModel } from '@models/cart/slot.model';
import { AppointmentRepository } from '@repositories/appointment.repository';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { CalendarEvent } from 'angular-calendar';
import { format, formatISO } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import bind from 'src/app/helper/decorators/bind';

import { CartTeleSalesService } from './cart-telesales.service';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    public skipStep = false;
    public currentAppointment: AppointmentModel;
    private readonly PREMIUM_SALE_GROUP_ID: string = '1034';

    constructor(
        private readonly cartTeleSalesService: CartTeleSalesService,
        private readonly appointmentRepository: AppointmentRepository,
        protected readonly faiEligibilityService: FaiEligibilityService,
        protected readonly customerService: CustomerService,
    ) {
        this.currentAppointment = this.appointmentRepository.getCurrentAppointment();
    }

    public getAvailableAppointements(): Observable<CalendarEvent<SlotModel>[]> {
        const faiQuoteIndex: number = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        const plan: Fai = this.cartTeleSalesService.cartModel.getQuote(faiQuoteIndex).getPrincipalProduct<Fai>('Fai');
        return this.appointmentRepository.getOffers(plan.gencode).pipe(
            mergeMap((offer) =>{
                this.currentAppointment.isPremuim = offer.famillesVente?.some((f) => f.idFamilleVente === this.PREMIUM_SALE_GROUP_ID);
                this.appointmentRepository.save(this.currentAppointment);
                return this.appointmentRepository.getAvailableAppointments(
                    this.cartTeleSalesService.cartModel,
                    this.faiEligibilityService.currentCart,
                    this.customerService.customer,
                    this.currentAppointment,
                    this.cartTeleSalesService.getCartService().getFaiService().currentCart?.id
                ).pipe(
                    map(this._mapToCalendarSlot)
                );
            }
            ));
    }

    public getDeliveryAppointements(address: AddressModel): Observable<CalendarEvent<DeliveryAppointmentModel>[]> {
        return this.appointmentRepository.getDeliveryAppointments(address).pipe(
            map(this._mapToCalendarSlot)
        );
    }

    public updateAppointment(inputData: IAppointmentForm): Observable<AppointmentModel> {
        const appointmentData = inputData.appointment;
        delete inputData.appointment;
        this.currentAppointment = Object.assign(this.currentAppointment, inputData, appointmentData);
        this.currentAppointment.dataToUpdate = this._getParamsToUpdate();
        this.appointmentRepository.save(this.currentAppointment);
        return of(this.currentAppointment);
    }


    @bind
    private _mapToCalendarSlot(slots: (SlotModel | DeliveryAppointmentModel)[]): CalendarEvent<SlotModel | DeliveryAppointmentModel>[] {
        return slots.map((slot) => ({
            id: slot.id,
            start: slot.start,
            end: slot.end,
            title: `De ${format(slot.start,'H:mm')} à ${format(slot.end ,'H:mm')}`,
            color: COLORS_APPOINTMENT.blue,
            meta: slot}));

    }

    private _getParamsToUpdate(): IFaiAppointmentUpdatedParams{
        const params: IFaiAppointmentParams = this.appointmentRepository.getGenericParamsSlots(
            this.cartTeleSalesService.cartModel,
            this.faiEligibilityService.currentCart,
            this.customerService.customer,
            this.currentAppointment,
            true
        );
        return {
            information: {
                civility: params.client.civilite,
                telContact: this.currentAppointment.contactNumber,
                firstName: params.client.nom,
                lastName: params.client.prenom,
                digicode: this.currentAppointment.digicode,
                interphone: this.currentAppointment.interphone,
                comment: this.currentAppointment.comment
            },
            appointment: {
                ...params,
                idCreneau: this.currentAppointment.id,
                debutCreneau: formatISO(this.currentAppointment.start),
                finCreneau: formatISO(this.currentAppointment.end),
                idPrestataire: this.currentAppointment.providerId,
                telContactClient: this.currentAppointment.contactNumber,
                commentaire: this.currentAppointment.comment,
                digicode: this.currentAppointment.digicode,
                interphone: this.currentAppointment.interphone,
                plageDisponibiliteClient: this.currentAppointment.disponibilitySlot,
                typeCalendrier: this.currentAppointment.calendarType,
                eligibiliteCreneauxSpecifiques: this.currentAppointment.EligibilitySpecificSlots
            }

        };
    }

}
