<div class="box" [formGroup]="portabilityForm">
  <div class="box-content has-text-left">
    <div class="columns is-vcentered is-multiline" data-cy="block-portability">
      <div class="column is-8-widescreen is-7-desktop is-6-tablet">
        <div class="is-size-4 has-text-weight-semibold icon-and-text">
          <span bytelTranslate="panier.portabilite.titre">Je conserve mon numéro</span>
          <div class="popover is-popover-left">
            <span class="icon is-small">
              <i class="tri-question-circle"></i>
            </span>
            <div class="popover-content">
              <p bytelTranslate="panier.portabilite.informations.resiliation">Nous nous occupons de
                résilier votre forfait auprès de votre ancien opérateur, du
              transfert de votre numéro de mobile et de l’activation de votre ligne.</p>
              <p bytelTranslate="panier.portabilite.informations.automatique">C’est automatique, vous
                n’avez rien à faire et vous êtes prévenu de l’activation de votre
              ligne par SMS et par email.</p>
            </div>
          </div>
        </div>
        @if (portaPromo) {
          @if (portaPromo.message.portability_non) {
            @if (!portabilityForm.controls.checked.value) {
              <p>{{portaPromo.message.portability_non}}</p>
            }
          } @else {
            @if (!portabilityForm.controls.checked.value) {
              <p bytelTranslate="panier.portabilite.informations.avantages.partie_1">Conservez votre
                numéro et bénéficiez d’une remise de <span class="has-text-weight-semibold"
                bytelTranslate="panier.portabilite.informations.avantages.partie_2_gras">-15€ pendant 12
              mois</span></p>
            }
          }
          @if (portaPromo.message.portability_yes) {
            @if (portabilityForm.controls.checked.value) {
              <p>{{portaPromo.message.portability_yes}}</p>
            }
          } @else {
            @if (portabilityForm.controls.checked.value) {
              <p bytelTranslate="panier.portabilite.informations.prix.partie_1">Les prix affichés
                incluent <span class="has-text-weight-semibold"
                bytelTranslate="panier.portabilite.informations.prix.partie_2_gras">15€/mois de
              remise</span><span bytelTranslate="panier.portabilite.informations.prix.partie_3"> immédiate
            pendant 12 mois</span></p>
          }
        }
      }
    </div>

    <div class="column is-4-widescreen is-5-desktop is-6-tablet">
      <div class="options buttons is-centered" data-cy="choice-portability">
        <div class="field">
          <div class="control has-text-centered">
            <input type="radio" formControlName="checked" [value]="true" id="portability_choice_yes"
              name="checked" data-cy="choice-portability-yes">
            <label for="portability_choice_yes" class="button is-size-4"
            bytelTranslate="panier.portabilite.choix.oui">Oui</label>
          </div>
        </div>
        <div class="field">
          <div class="control has-text-centered">
            <input type="radio" id="portability_choice_no" formControlName="checked" [value]="false"
              name="checked" data-cy="choice-portability-no">
            <label for="portability_choice_no" class="button is-size-4"
            bytelTranslate="panier.portabilite.choix.non">Non</label>
          </div>
        </div>
      </div>
      @if (isSubmitted && portabilityForm.invalid && portabilityForm.get('checked').value) {
        <ul class="help is-danger column is-12">
          <li class="has-text-right" bytelTranslate="panier.portabilite.requis">Merci d'indiquer votre choix
            de portabilité.
          </li>
        </ul>
      }
    </div>
  </div>
  @if (portabilityForm.get('phoneNumber').enabled || portabilityForm.get('codeRio').enabled) {
    <div class="is-divider"></div>
    <div class="column is-8" data-cy="form-portability">
      <div class="field">
        <div class="control has-dynamic-placeholder has-icons-right">
          <input tlvFormClass [tlvFormClassErrorControl]="this.portabilityForm.get('phoneNumber')"
            class="input is-focused"
            formControlName="phoneNumber"
            id="phone" maxlength="10" minlength="10" placeholder="n° de téléphone à conserver *"
            type="text" data-cy="form-portability-mobile">
          <label for="phone" bytelTranslate="panier.portabilite.telephone.titre">n° de téléphone</label>
          @if (this.portabilityForm.get('phoneNumber').dirty) {
            <span class="icon is-small">
              @if (this.portabilityForm.get('phoneNumber').valid && !this.formValidatorLoading) {
                <i class="tri-check-circle"></i>
              }
              @if (this.formValidatorLoading) {
                <i class="is-searching"></i>
              }
              @if (this.portabilityForm.get('phoneNumber').invalid) {
                <i  class="tri-exclamation-circle"></i>
              }
            </span>
          }
        </div>
        @if (portabilityForm.get('phoneNumber').touched && portabilityForm.get('phoneNumber').invalid) {
          <p class="has-text-danger" data-cy="portability-mobile-error">
            <container-element>
              @switch (true) {
                @case (!!portabilityForm.get('phoneNumber').errors.required) {
                  Numéro requis
                }
                @case (!!portabilityForm.get('phoneNumber').errors.minlength || !!portabilityForm.get('phoneNumber').errors.pattern) {
                  Numéro incorrect
                }
              }
            </container-element>
          </p>
        }
      </div>
      <div class="field">
        <p><span class="has-text-weight-semibold"
          bytelTranslate="panier.portabilite.mobile.rio.partie_1_gras">Appeler le 3179
        </span><span bytelTranslate="panier.portabilite.mobile.rio.partie_2"> pour connaitre votre n° de
      RIO</span></p>
      <div class="control has-dynamic-placeholder has-icons-right">
        <input tlvFormClass [tlvFormClassErrorControl]="portabilityForm.get('codeRio')"
          class="input is-focused" formControlName="codeRio" maxlength="12"
          minlength="12" placeholder="n° de RIO * (sans espace)" type="text" id="rio" data-cy="form-portability-rio">
        <label for="rio" bytelTranslate="panier.portabilite.rio">n° de RIO</label>
        @if (portabilityForm.get('codeRio').dirty) {
          <span class="icon is-small">
            @if (this.portabilityForm.get('codeRio').valid && !this.formValidatorLoading) {
              <i class="tri-check-circle"></i>
            }
            @if (this.formValidatorLoading) {
              <i class="is-searching"></i>
            }
            @if (this.portabilityForm.get('codeRio').invalid) {
              <i class="tri-exclamation-circle"></i>
            }
          </span>
        }
      </div>
      @if (portabilityForm.errors?.apiError || (portabilityForm.get('codeRio').touched && portabilityForm.get('codeRio').invalid)) {
        <p class="has-text-danger">
          <container-element data-cy="portability-rio-error">
            @switch (true) {
              @case (!!portabilityForm.get('codeRio')?.errors?.required) {
                Numéro requis
              }
              @case (!!portabilityForm.get('codeRio')?.errors?.rio) {
                RIO incorrect
              }
              @case (!!portabilityForm?.errors?.apiError) {
                {{portabilityForm?.errors?.apiError}}
              }
            }
          </container-element>
        </p>
      }
    </div>
  </div>
  @if (!!messagePortaPromo) {
    <div class="column is-12 is-block">
      <p class="has-text-grey-dark is-italic">
        {{ messagePortaPromo }}
      </p>
    </div>
  }
}
</div>
</div>
