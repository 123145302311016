import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[checkoutProductBlockHost]',
    standalone: false
})
export class ProductBlockDirective {
    constructor(public viewContainerRef: ViewContainerRef) {}
}
