import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPaymentFormData, PAYMENT_METHODS } from '@interfaces/payment.interface';
import { OrderRepository } from '@repositories/order.repository';
import { SalesService } from '@services/checkout/sales.service';
import { OrderService } from '@services/order.service';
import { SalesUserService } from '@services/sales-user.service';
import { of } from 'rxjs';
import { catchError, delayWhen, finalize } from 'rxjs/operators';
import { CallRecordService } from '@services/call-record.service';
import { OrderModel } from '@models/order/order.model';

interface ErrorFeedback {
    code: number;
    error: string;
    errorWithRedirect: string;
}

@Component({
    selector: 'tlv-payment-failure',
    templateUrl: './payment-failure.component.html',
    styleUrls: ['./payment-failure.component.scss'],
    standalone: false
})
export class PaymentFailureComponent implements OnInit {

    public errorMessage: string;
    public isLoading = true;
    public isPerformingAction = false;
    public paymentActions: {
        transformToHybrid?: boolean;
        paymentRetrival?: boolean;
    };
    public order: OrderModel;
    public actionError: string;

    public paymentData: IPaymentFormData = {
        url: null,
        method: null,
        inputs: []
    };
    // Native form is used to avoid CORS problem with external url from payment responses
    @ViewChild('paymentDataFormElement') paymentDataFormElement;

    private _declinedPaymentMessage = 'Votre paiement est refusé.';
    private _errorMessages: ErrorFeedback[] = [
        {code: 2, error: this._declinedPaymentMessage, errorWithRedirect: 'Votre paiement est refusé par votre banque.'},
         
        {code: 17, error: 'Vous avez annulé votre paiement.', errorWithRedirect: 'Votre paiement est refusé. Vous avez dépassé le nombre de tentative de saisie de numéro de carte bancaire.'},
        {code: 75, error: this._declinedPaymentMessage, errorWithRedirect: this._declinedPaymentMessage},
         
        {code: 101, error: 'Votre paiement est refusé, merci de renouveler votre paiement.', errorWithRedirect: 'Votre paiement est refusé. Vous avez dépassé le nombre de tentative de saisie de numéro de carte bancaire.'},
         
        {code: 102, error: 'Votre paiement est refusé par votre banque, merci d\'effectuer votre paiement avec une autre carte ou un autre moyen de paiement.', errorWithRedirect: 'Votre paiement est refusé. Vous avez dépassé le nombre de tentative de saisie de numéro de carte bancaire.'},
         
        {code: 103, error: 'Votre paiement est refusé, merci d\'effectuer votre paiement avec une carte bancaire Française.', errorWithRedirect: 'Votre paiement est refusé. Vous avez dépassé le nombre de tentative de saisie de numéro de carte bancaire.'},
         
        {code: 104, error: 'Votre paiement est refusé, merci d\'effectuer votre paiement sans utiliser une e-carte bleue.', errorWithRedirect: 'Votre paiement est refusé. Vous avez dépassé le nombre de tentative de saisie de numéro de carte bancaire.'},
        {code: 105, error: this._declinedPaymentMessage, errorWithRedirect: this._declinedPaymentMessage},
        {code: 106, error: this._declinedPaymentMessage, errorWithRedirect: this._declinedPaymentMessage},
        {code: null, error: this._declinedPaymentMessage, errorWithRedirect: this._declinedPaymentMessage},
    ];

    constructor(
        private orderRepository: OrderRepository,
        private route: ActivatedRoute,
        private router: Router,
        private ref: ChangeDetectorRef,
        private salesUserService: SalesUserService,
        private salesService: SalesService,
        private orderService: OrderService,
        private callRecordService: CallRecordService
    ) {
        this.callRecordService.resume();
    }

    public ngOnInit(): void {
        this.orderRepository.getOrderData(this.route.snapshot.params?.orderId)
            .subscribe(data => {
                if (!data?.length) {
                    this.errorMessage = this._getErrorMessage(this.route.snapshot.queryParams?.retour);
                    this.isLoading = false;
                    return;
                }
                this.order = data[0];
                this.errorMessage = this._getErrorMessage(this.route.snapshot.queryParams?.retour);
                this.paymentActions = this.order.initialPaymentData;
                this.isLoading = false;
            });
    }

    public endCall(): void {
        this.salesUserService.closeCall();
    }

    public convertOrderToHybrid(): void {
        this.isPerformingAction =  true;
        this._resetError();

        this.orderService.notityHybridPaymentConversion(this.order.id)
            .pipe(
                delayWhen(()=>this.callRecordService.pause()),
                finalize(() => this.isPerformingAction = false),
                catchError(err => {
                    this.actionError = err?.error?.error_description;
                    return of(null);
                })
            ).subscribe(() => {
                this.router.navigate([`/order-success/${this.order.id}`]);
            });
    }

    public retryPayment(): void {
        this.isPerformingAction =  true;
        this._resetError();

        this.salesService.pushPaymentMode(
            '/paiement/tickets-caisse',
            {
                idCommande: this.order.id,
                modeIntegrationPSP: 'PAGE',
                modePaiement: PAYMENT_METHODS.CB
            }
        )
            .pipe(
                delayWhen(()=>this.callRecordService.pause()),
                finalize(() => this.isPerformingAction = false),
                catchError(err => {
                    this.actionError = err?.error?.error_description;
                    return of(null);
                })
            )
            .subscribe(formData => {
                this.paymentData = formData.formDataToPost;
                this.ref.detectChanges();
                // This is done to avoid CORS on external url data post
                this.paymentDataFormElement.nativeElement.submit();
            });
    }

    private _resetError(): void {
        this.actionError = '';
    }

    private _getErrorMessage(code: number = null, withPaymentRetry = false): string {
        const error: ErrorFeedback = this._errorMessages.find((err: ErrorFeedback ) => err?.code === +code);
        if (!error) {
            return this._declinedPaymentMessage;
        }
        return withPaymentRetry ? error.errorWithRedirect : error?.error;
    }

}
