<div class="box" data-cy="deliveries">
  <div class="columns is-fullwidth is-vcentered is-marginless">
    <div class="column is-flex is-vcentered is-paddingless">
      <span class="icon is-medium has-text-info">
        <i class="tri-truck"></i>
      </span>
      <span class="is-size-3-tablet is-size-4 is-flex is-vcentered">
        Choix du mode de livraison&nbsp;
        <span class="shipping-methods-modal-link">
          <a class="link is-size-6" (click)="moreInfo()" data-cy="show-click-and-collect-modal">Comprendre les modes de livraison</a>
        </span>
      </span>
    </div>
  </div>
  <div class="is-divider is-stretched"></div>
  <div class="columns is-multiline is-marginless is-centered">
    @if (isQuoteMixed && !hasESimInCart) {
      <div class="has-body is-small notification">
        <div class="icon is-flex">
          <i class="tri-infos-circle" aria-hidden="true"></i>
        </div>
        <div class="body">
          <p class="text is-level-2 is-marginless">Dans le cadre de votre commande, <strong>la carte SIM </strong>associée au forfait mobile sera directement <strong>envoyée à votre domicile</strong>.</p>
        </div>
      </div>
    }
    <div class="column is-12">
      <p byteltranslate="panier.livraison.categories.ou" class="has-text-centered has-text-weight-bold">Où souhaitez-vous être livré ?</p>
    </div>
    <div class="column is-12">
      <div class="tabs is-centered is-justified-center is-clipped" role="tablist">
        <ul>
          @for (cat of availableDelivery; track cat) {
            <li role="presentation">
              <a role="tab" [data-cy]="'delivery-cat-'+cat.code" (click)="changeCat(cat)" [ngClass]="{'is-active':cat.code === catSelected}" aria-selected="true">{{cat.name}}</a>
            </li>
          }
        </ul>
      </div>
    </div>
    <div class="column">
      @if (!availableDelivery?.length) {
        <tlv-loader loadingText="Chargement des modes de livraison en cours..."></tlv-loader>
      }
    </div>
    @if (availableDelivery?.length) {
      <div class="column is-12">
        <div class="columns is-centered is-multiline">
          @for (cat of availableDelivery; track cat) {
            @if (cat.code === catSelected) {
              @for (shippingMethod of cat.products; track shippingMethod; let i = $index) {
                @if (deliveryInfo[shippingMethod.gencode]) {
                  <div [ngClass]="cat.products.length === 4 && (i % 2 === 0) && 'is-offset-1'"
                    class="column is-4-tablet" [data-cy]="'delivery-'+shippingMethod.gencode">
                    <div class="pricing-table is-marginless">
                      <div
                        class="has-hat"
                        (click)="selectShippingMethod(deliveryInfo[shippingMethod.gencode].info.product)"
                        [data-cy]="'delivery-method-'+shippingMethod.gencode"
                        >
                        @if (shippingMethod.gencode === 'CLICK_COLLECT') {
                          <div class="sticker is-secondary is-hat">
                            LE PLUS RAPIDE
                          </div>
                        }
                        <div class="sticker is-tertiary is-hat"
                          [ngClass]="{'is-invisible': !deliveryInfo[shippingMethod.gencode].info.isTomorrowDeliverable()}">
                          <span>Livré dès demain</span>
                        </div>
                        <a class="box"
                          [ngClass]="{'is-selected-shipping': shippingMethod.gencode === selectedMethod?.gencode}">
                          <div class="shipping-method">
                            <div class=" is-flex is-vcentered">
                              <div class="control">
                                <input class="input is-checkradio" type="radio"
                                  name="address[shipping_method]"
                                  [id]="'radio' + shippingMethod.gencode"
                                  [checked]="shippingMethod.gencode === selectedMethod?.gencode">
                                <label for="'radio' + shippingMethod.gencode"></label>
                              </div>
                            </div>
                            <div class="is-flex is-vcentered">
                              <span class="icon is-medium has-text-secondary">
                                <i [ngClass]="SHIPPING_ICONS[shippingMethod.gencode]"></i>
                              </span>
                            </div>
                            <div class="shipping-method-infos has-text-left is-paddingless">
                              <div>
                                <p class="is-uppercase has-text-weight-bold is-marginless is-size-7">{{deliveryInfo[shippingMethod.gencode].info.libelle}}</p>
                                <div class="is-inline-flex is-multiline">
                                  @if (deliveryInfo[shippingMethod.gencode].info.price > 0) {
                                    <tlv-price
                                      [price]="deliveryInfo[shippingMethod.gencode].info.price" class="is-margin"
                                    size-3></tlv-price>
                                  }
                                  @if (deliveryInfo[shippingMethod.gencode].info.price <= 0) {
                                    <p
                                      class="is-margin is-uppercase has-text-weight-bold"
                                    bytelTranslate="panier.livraison.methodes_livraison.le_plus_rapide.gratuit">Gratuit</p>
                                  }
                                  <div class="is-fullwidth">
                                    @switch (shippingMethod.gencode) {
                                      @case ('CLICK_COLLECT') {
                                        <p [ngClass]="shippingMethod?.gencode === selectedMethod?.gencode ? 'has-text-primary': 'has-text-tertiary'"
                                          class="is-size-8 is-marginless shipping-method-centered-item">
                                        Dès 2 heures en boutique</p>
                                        <div class="popover is-popover-left click-collect"
                                          (click)="openClickCollectModal()">
                                          <span class="icon is-small">
                                            <i class="tri-question-circle"></i>
                                          </span>
                                          <div class="popover-content">
                                            <p>
                                              Cliquez pour obtenir plus d'informations
                                            </p>
                                          </div>
                                        </div>
                                      }
                                      @case ('LIVRAISON_RDV') {
                                        <p [ngClass]="shippingMethod?.gencode === selectedMethod?.gencode ? 'has-text-primary': 'has-text-tertiary'"
                                          class="is-size-8 is-marginless shipping-method-centered-item">
                                        Choisir un créneau de 2H</p>
                                        <div class="popover is-popover-left">
                                          <span class="icon is-small">
                                            <i class="tri-question-circle"></i>
                                          </span>
                                          <div class="popover-content">
                                            <p>
                                              Créneau de 4h le dimanche
                                            </p>
                                          </div>
                                        </div>
                                      }
                                      @default {
                                        <div class="is-flex is-vcentered">
                                          <p [ngClass]="shippingMethod?.gencode === selectedMethod?.gencode ? 'has-text-primary': 'has-text-tertiary'"
                                            class="is-size-8 is-marginless shipping-method-centered-item"><span>Entre
                                          le </span>{{ deliveryInfo[shippingMethod.gencode].info.estimatedDeliveryDate | date:'dd/MM' }}
                                          <span> et
                                          le </span>{{ deliveryInfo[shippingMethod.gencode].info.lateEstimatedDeliveryDate | date:'dd/MM' }}
                                        </p>
                                        <div class="popover is-popover-left">
                                          <span class="icon is-small">
                                            <i class="tri-question-circle"></i>
                                          </span>
                                          <div class="popover-content">
                                            <p>
                                              Hors dimanche et jours fériés
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              }
            }
            <div class="is-12 column">
              @if (selectedMethod?.gencode === 'POINT_RELAIS_EXPRESS') {
                <div class="has-body is-small is-warning notification">
                  <div class="body">
                    <div class="columns is-multiline is-marginless">
                      <div class="column is-12 is-flex is-vcentered">
                        <div class="icon">
                          <i class="tri-exclamation-circle"></i>
                        </div>
                        &nbsp;&nbsp;
                        <h5 class="title">Une fois votre colis disponible en point Relais, vous disposez d'un délai de 7 jours pour le récupérer.</h5>
                      </div>
                    </div>
                  </div>
                </div>
              }
              @if (stores && stores.length) {
                <bytel-store-locator
                  (editAddressEvent)="editAdress()"
                  (selectedStore)="selectStore($any($event))"
                  [currentStore]="currentStore"
                  [mapConfig]="mapConfig"
                  [address]="address.getInline()"
                  [stores]="stores"
                  [showDeliveryMapIcon]="true"
                ></bytel-store-locator>
              }
            </div>
            @if (selectedMethod?.gencode === 'LIVRAISON_RDV') {
              <div class="is-12 column">
                @if (hasAppointment) {
                  <bytel-appointments-calendar
                    [appointments$]="obsSlots"
                    [storedAppointment]="$any(currentAppointment)"
                    [appointmentErrorSubject]="errorsCalendarSubject"
                    headerTextAppointment="Sélectionner un créneau de livraison"
                    (selectAppointmentEmitter)="selectSlot($event)">
                  </bytel-appointments-calendar>
                } @else {
                  <div class="has-text-centered">
                    <span class="icon is-huge">
                      <i class="tri-calendar-unavailable"></i>
                    </span>
                    <h2 class="is-3 title is-marginless">Aïe</h2>
                    <p>Un incident technique est survenu.</p>
                    <p>Nous ne pouvons afficher le calendrier, veuillez sélectionner un autre mode de livraison.</p>
                    <p>Nous vous prions de nous excuser pour le désagrément occasionné.</p>
                  </div>
                }
              </div>
            }
          }
        }
      </div>
    </div>
  }
</div>
<div class="buttons">
  <button (click)="submit()" [data-cy]="'delivery-submit'" [disabled]="!ready || isLoading"
  class="button is-primary">{{isLoading ? 'Traitement en cours...' : 'Valider'}}</button>
</div>
</div>
