@if (steps.length) {
  <section class="section has-text-centered has-background-white" data-cy="checkout-breadcrumb">
    <nav aria-label="breadcrumbs" class="breadcrumb">
      <ul>
        @for (step of steps; track step; let index = $index) {
          <li>
            <a (click)="goToStep(step)"
              [ngClass]="{'is-active has-text-tertiary': step.status === STATUS.INPROGRESS, 'has-text-grey': step.status !== STATUS.INPROGRESS}"
              aria-current="page" class="is-size-6 is-size-5-mobile">
              {{index + 1}}<span [ngClass]="{'is-hidden-mobile': step.status !== STATUS.INPROGRESS}">. {{step.label}}</span>
            </a>
          </li>
        }
      </ul>
    </nav>
  </section>
}
