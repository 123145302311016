import { Pipe, PipeTransform } from '@angular/core';

enum ENGAGEMENT_LABEL {
    monthly12= 'Engagement 12 mois',
    monthly24 = 'Engagement 24 mois',
    none = 'Sans engagement'
}

@Pipe({
    name: 'engagementLabel',
    pure: true,
    standalone: false
})

export class EngagementLabelPipe implements PipeTransform {

    transform(value: string): string {
        return ENGAGEMENT_LABEL[value] || ENGAGEMENT_LABEL.none;
    }
}
