import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { AddressModel } from '@models/cart/address.model';
import { AddressService } from '@services/checkout/address.service';
import { ValidatorsService } from '@services/validators.service';
import { forkJoin, of } from 'rxjs';

@Component({
    selector: 'tlv-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    standalone: false
})
export class AddressComponent implements OnChanges, OnDestroy {

    @Input()
    public wording: {
        add: string;
        confirm: string;
    } = {add:'',confirm:''};
    @Input()
    public editable: boolean;
    @Input()
    public deliveryAddress: AddressModel;
    public shippingModesError: string;
    @Output()
    public onSubmit: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();
    public loading = false;
    public loadingAddress = false;
    public isAutomatic = true;
    public showFormAddress = false;
    public isAddressFromStorage = false;

    constructor(private readonly validatorsService: ValidatorsService,
                private changeDetectorRef: ChangeDetectorRef,
                private readonly addressService: AddressService) {
    }

    public ngOnDestroy(): void {
        this.changeDetectorRef?.detach();
    }

    public ngOnChanges(): void{
        if (!this.deliveryAddress){
            this.showFormAddress = true;
        } else {
            this.deliveryAddress = new AddressModel(this.deliveryAddress);
            this.submit();
        }
    }

    public switchMode(): void {
        this.isAutomatic = !this.isAutomatic;
        this.changeDetectorRef.detectChanges();
    }

    public submit(): void{
        if ((this.deliveryAddress.valid || this.deliveryAddress.confirmed) && this.deliveryAddress.lng && this.deliveryAddress.lat){
            this.onSubmit.emit(this.deliveryAddress);
            this.showFormAddress = false;
            return;
        }
        this.loading = true;
        forkJoin({
            validation: this.deliveryAddress.valid ?
                of(this.deliveryAddress) : this.validatorsService.addressValidation(this.deliveryAddress),
            address: (!this.deliveryAddress.lng || !this.deliveryAddress.lat) ?
                this.addressService.loadLocationInAddress(this.deliveryAddress) : of(this.deliveryAddress)
        }).subscribe(({validation,address})=>{
            this.showFormAddress = false;
            this.deliveryAddress = Object.assign(address,validation,{complement:address.complement});
            this.deliveryAddress.valid = validation.valid;
            this.deliveryAddress.confirmed = validation.valid;
            if (validation.valid){
                this.onSubmit.emit(this.deliveryAddress);
            } else {
                this.editable = true;
            }
            this.loading = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    public enableModification(): void {
        this.isAutomatic = true;
        this.showFormAddress = true;
        this.deliveryAddress.confirmed = false;
        this.onSubmit.emit(null);
        this.changeDetectorRef.detectChanges();
    }

    public setShippingModesError(error: string): void {
        this.shippingModesError = error;
        this.changeDetectorRef.detectChanges();
    }

    public confirm(): void {
        this.deliveryAddress.confirmed = true;
        this.onSubmit.emit(this.deliveryAddress);
        this.changeDetectorRef.detectChanges();
    }

    public onDeliveryAddressChange(evt): void {
        this.deliveryAddress = evt;
        this.changeDetectorRef.detectChanges();
    }
}
