import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BasicObject } from '@common-modules';
import { USER_TITLES } from 'src/app/constants/customer';
import { regions } from 'src/app/components/shared/regions';
import { CustomerService } from '@services/customer/customer.service';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { ScoringService } from '@services/checkout/scoring.service';
import { SalesForceService } from '@services/salesforce.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tlv-customer-info',
    templateUrl: './customer-info.component.html',
    styleUrls: ['./customer-info.component.scss'],
    standalone: false
})
export class CustomerInfoComponent implements OnInit, OnDestroy {

    @Input() public customer: CustomerSearchModel;
    @Input() public searchObject: BasicObject;
    @Output() savedEvent: EventEmitter<string> = new EventEmitter<string>();

    public isLoading = false;
    public regions: BasicObject = regions;
    public isSubmitted = false;
    public civilities = USER_TITLES;
    public formsFieldsAttributes: BasicObject = {};
    public isClientLockByScoring = false;

    private _subscriptions: Subscription[] = [];

    constructor(
        private customerService: CustomerService,
        private scoringService: ScoringService,
        private salesforceService: SalesForceService,
        private router: Router
    ) {

    }

    public ngOnInit(): void {
        this.scoringService.scoring$.subscribe( () => {
            this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring();
        });
        if (this.salesforceService.prefilledInfo.order) {
            this.nextStep();
            this.router.navigate(['fai', 'housing']);
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public nextStep(): void {
        this.isLoading = true;
        this._subscriptions.push(
            this.customerService.setCustomer(this.customer).subscribe({
                next: () => {
                    this.savedEvent.emit('saved');
                },
                complete: () => {
                    this.isLoading = false;
                }
            })
        );
    }

}
