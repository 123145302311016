import { Pipe, PipeTransform } from '@angular/core';

enum EQUIPMENTS_LABEL {
    internet_box_tv_hdr = '1 modem Internet Bbox Fibre Wifi 6 et 1 décodeur Bbox 4K HDR',
    internet_box_tv_4KHDR = '1 modem internet et 1 décodeur TV 4K HDR',
    internet_extended_box_tv_hdr = '1 modem Internet Bbox Wifi 6E et 1 décodeur Bbox 4K HDR',
    internet_extended = '1 modem Internet Bbox Wifi 6E',
    internet_box_tv_W6 = '1 modem Bbox Wifi 6+ et 1 décodeur Bbox 4K',
    internet_box_tv = '1 modem internet et 1 décodeur Bbox 4K',
    internet_box_tv_eole = '1 modem internet Bbox WIFI 6 et 1 décodeur Bbox 4K',
    internet_eole = '1 modem internet Bbox WIFI 6',
    internet_tv = '1 modem internet et 1 décodeur TV',
    internet = '1 modem internet',
    BOX_4G = '4G box',
    BOX_5G = '5G box',
    none = 'Aucun',
    internet_box_tv_W7 = '1 modem internet Bbox WiFi 7 et 1 décodeur Bbox 4K HDR',
    internet_box_W7 = '1 modem internet Bbox WiFi 7',
    internet_box_tv_W_4K = '1 modem internet Bbox WIFI et 1 décodeur Bbox 4K',
    internet_box_W = '1 modem internet Bbox WiFi'
}
@Pipe({
    name: 'equipmentLabel',
    pure: true,
    standalone: false
})

export class EquipmentLabelPipe implements PipeTransform {

    public transform(value: string): string {
        return EQUIPMENTS_LABEL[value] || EQUIPMENTS_LABEL.none;
    }
}
