<div class="box is-flat">
  <div class="box-content has-background-grey-lighter">
    <!-- block customer -->
    <h5 class="title is-size-4" [data-cy]="'info-title'">
      @if (customerPro) {
        <span> SIREN : {{customerPro.siren }} / RAISON SOCIALE : {{customerPro.socialReason }}</span>
      }
      @if (customerGp) {
        <span>{{customerGp.gender }}. {{customerGp.lastname }} {{customerGp.firstname }} - né(e) le : {{customerGp.birthDate | date:'dd/MM/yyyy' }} </span>
      }
    </h5>
    <!-- block contract -->
    <div class="columns is-6 is-multiline">
      <div class="column is-12">
        @for (contract of contracts; track contract) {
          <div data-cy="billingAccount">
            <hr class="is-divider">
            <span>Référence N°: {{ contract.noReference }} -</span>
            <span class="has-text-weight-semibold"> {{ contract.ligneMarche }} </span>
            @if (contract.msisdn) {
              <span> {{ contract.msisdn }} -</span>
            }
            @if (contract.offrePrincipale.libelle) {
              <span> {{ contract.offrePrincipale.libelle }} - </span>
            }
            @if (customer.email) {
              <span> {{ customer.email }} - </span>
              }<span class="has-text-weight-semibold is-uppercase">{{ contract.statut }}</span>
            </div>
          }
      </div>      
    </div>
  </div>
</div>
@if (isBtnValid) {
  <div class="field has-text-centered">
    <button [disabled]="isClientLockByScoring || isLoading" type="button" data-cy="customer-info-submit" class="button is-small is-primary" (click)="nextStep()">Continuer</button>
  </div>
}

