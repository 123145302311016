import { Injectable } from '@angular/core';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { AccessoryService } from '@services/checkout/accessory.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Option, Plan } from '@bytel/bytel-sales';
import { DisplayOptionModel } from '@models/cart/display-option.model';

@Injectable({
    providedIn: 'root'
})
export class CrossSellStep implements IStep{

    public icon = 'cross-sell';
    public name = 'Options-accessoire';
    public label = 'Compléments';
    public route: string[] = [
        'panier',
        'options-accessoires'
    ];
    public status: STEP_STATUS = STEP_STATUS.WAITING;
    public canActive = false;
    public canBeValidate = true;

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        protected accessoryService: AccessoryService,
        protected optionsAvailableService: OptionsAvailableService
    ) {}

    public validateStep(): boolean {
        return true;
    }

    public canAccess(): boolean {
        return this.canDisplay();
    }

    public canActivate(): Observable<boolean> {
        return this.cartTeleSalesService.resolve().pipe(map(()=>this.canAccess()));
    }

    public canDisplay(): boolean {
        const plan: Plan = this.cartTeleSalesService.cartModel.getQuote().getProductByType(Plan);
        const options: Option[] = this.optionsAvailableService?.options[plan?.gencode] ?? [];
        let canActiveOpt = !!options.length || options.some(o => !!(o as DisplayOptionModel).firstPage);
        let canActiveAcc = !!this.accessoryService?.accessories.length;
        this.optionsAvailableService.update$.subscribe((opts)=> {
            const planOptions: Option[] = opts[plan?.gencode];
            canActiveOpt = planOptions?.length > 0 || planOptions?.some((opt) => (opt as DisplayOptionModel).firstPage);
            if (this.cartTeleSalesService.isQuoteMixed()) {
                canActiveOpt = Object.keys(this.optionsAvailableService?.options)
                    .some(gencode => this.optionsAvailableService?.options[gencode].length);
            }
        });
        this.accessoryService.update$.subscribe((accs)=> {
            canActiveAcc = accs.length > 0;
        });
        this.canActive = canActiveOpt || canActiveAcc;
        return this.canActive;
    }
}
