@if (suggestions.length) {
  <div class="box">
    <div class="box-content">
      <div class="columns is-multiline">
        <div class="column is-narrow is-paddingless has-text-right is-pos-sticky is-12">
          <span class="icon is-small has-cursor" (click)="suggestions = []"><i class="tri-times-circle"></i></span>
        </div>
        <div class="column suggestion-items">
          @for (suggestion of suggestions; track suggestion) {
            @if (wordsSuggestions[suggestion.toLowerCase()]) {
              <p><span class="has-text-weight-semibold is-size-5 is-uppercase">{{suggestion}}</span> comme <span class="is-capitalized has-text-weight-semibold">{{wordsSuggestions[suggestion][0]}}</span>{{wordsSuggestions[suggestion].substr(1)}}</p>
            } @else {
              <p class="has-text-weight-semibold is-size-5">{{suggestion}} est un caractère interdit</p>
            }
          }
        </div>
      </div>
    </div>
  </div>
}