import { Injectable } from '@angular/core';
import { BasicObject, Oauth2ResourcesService } from '@common-modules';
import { ICommercialProposal } from '@interfaces/commercial-proposal.interface';
import { IDownloadLinkUniversalModel } from '@interfaces/download-universal-link.interface';
import { IDocumentResponse } from '@interfaces/search-document.interface';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommercialProposalRepository {

    constructor(private oauthService: Oauth2ResourcesService) { }

    public getProposalOrder(noPersonne: string): Observable<ICommercialProposal[]> {
        return this.oauthService
            .useSalesApi()
            .propositionsCommerciales()
            .setParams({ noPersonne })
            .get()
            .pipe(
                map((data: ICommercialProposal[]) => {
                    const mappedData = data.map((item: ICommercialProposal) => ({
                        acteurCreateur: item.acteurCreateur,
                        dateCreation: new Date(item.dateCreation),
                        identifiant: item.identifiant,
                        typeParcours: item.typeParcours
                    }) as ICommercialProposal);
                    return mappedData;
                })
            );
    }

    public getProposalOrderProspect(email: string, idProspect?: string): Observable<ICommercialProposal[]> {
        const params: BasicObject = idProspect ? { idProspect } : { email };
        return this.oauthService
            .useSalesApi()
            .propositionsCommerciales()
            .setParams(params)
            .get()
            .pipe(
                map((data: ICommercialProposal[]) => {
                    const mappedData = data.map((item: ICommercialProposal) => ({
                        acteurCreateur: item.acteurCreateur,
                        dateCreation: new Date(item.dateCreation),
                        identifiant: item.identifiant,
                        typeParcours: item.typeParcours
                    }));
                    return mappedData;
                })
            );
    }

    public getDocument(idPropositionCommerciale: string): Observable<IDocumentResponse> {
        const params = { idPropositionCommerciale };
        return this.oauthService
            .useSalesApi()
            .documents()
            .setParams(params)
            .get();
    }

    public getUniversalDownloadLink(idDocument: string): Observable<IDownloadLinkUniversalModel> {
        const params = { idDocument };
        return this.oauthService
            .useSalesApi()
            .downloadDocument()
            .setParams(params)
            .get();
    }
}
