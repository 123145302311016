<div class="box" data-cy="family-pack-control">
  @if (isPromoLoading) {
    <div>
      <tlv-loader iconSize="medium" loadingText="Mise à jour des promotions en cours..."></tlv-loader>
    </div>
  }
  @if (!isPromoLoading) {
    <div class="box-content has-text-left">
      <div class="columns is-marginless is-vcentered">
        <div class="column is-8-widescreen is-7-desktop is-6-tablet">
          <div class="is-size-4 has-text-weight-semibold icon-and-text">
            <span>B.iG</span>
          </div>
          <p>Souhaitez-vous modifier les économies {{isFai ? 'fixes' : 'mobiles'}} calculées au panier {{automaticPromotionsSum === 0 ? '' : '(-' + automaticPromotionsSum + '€)'}}?</p>
        </div>
      </div>
      <div [formGroup]="familyPackForm" class="row has-text-centered">
        <div class="is-small has-body is-shadowless">
          <div class="is-stretched block-element">
            <div class="is-divider"></div>
          </div>
          <div class="body columns is-fullwidth is-centered">
            <div class="column is-3">
              <div class="is-flex-mobile has-cursor">
                <div class="is-flex is-justified-center mobile-salign-center mobile-order-1">
                  <div class="control">
                    <input class="input is-checkradio"
                      name="familyPack"
                      [value]=false
                      formControlName="familyPack"
                      id="none-familyPack"
                      type="radio"
                      data-cy="form-family-pack-no">
                    <label for="none-familyPack"></label>
                  </div>
                </div>
                <div class="is-block mobile-order-3 has-text-left-mobile">
                  <p>Non</p>
                </div>
              </div>
            </div>
            @for (manualPromo of manualPromos; track manualPromo) {
              <div class="column is-3">
                <div class="is-flex-mobile has-cursor">
                  <div class="is-flex is-justified-center mobile-order-1 mobile-salign-center">
                    <div class="control">
                      <input formControlName="familyPack"
                        name="familyPack"
                        [value]=manualPromo.id
                        [data-cy]="'select_manual_promo_'+manualPromo.id" class="input is-checkradio"
                        type="radio"
                        id='promoManual_{{manualPromo.id}}-{{manualPromo.addProduct}}'
                        >
                      <label for="promoManual_{{manualPromo.id}}-{{manualPromo.addProduct}}"></label>
                    </div>
                  </div>
                  <div class="is-block mobile-order-3 has-text-left-mobile">
                    <p bytelTranslate="">{{manualPromo.name}}
                    </p>
                    @if (manualPromo.amount > 0) {
                      <tlv-price [hasPeriod]="true" [price]="manualPromo.amount"
                      size-4></tlv-price>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
</div>
