import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEXS } from '@common-modules';
import { AddressModel } from '@models/cart/address.model';
import bind from '../../../../../helper/decorators/bind';

@Component({
    selector: 'tlv-manual-address',
    templateUrl: './manual-address.component.html',
    styleUrls: ['./manual-address.component.scss'],
    standalone: false
})
export class ManualAddressComponent implements OnChanges{

    @Input()  value: AddressModel;
    @Output() valueChange = new EventEmitter<AddressModel>();

    public addressForm: FormGroup = new FormGroup({
        streetNumber: new FormControl<string | null>(null, Validators.pattern(REGEXS.POSTAL_ADDRESS_REGEXP)),
        street: new FormControl<string | null>(null, [
            Validators.required,
            Validators.pattern(REGEXS.POSTAL_ADDRESS_REGEXP)
        ]),
        city: new FormControl<string | null>(null, [Validators.required]),
        complement: new FormControl<string | null>(null, Validators.pattern(REGEXS.POSTAL_ADDRESS_REGEXP)),
        postalCode: new FormControl<string | null>(null, [
            Validators.required,
            Validators.pattern(REGEXS.ZIP_CODE_REGEXP)
        ]),
    });

    constructor() {
        this.addressForm.valueChanges.subscribe(this.formChange);
    }

    public ngOnChanges(): void {
        this.addressForm.patchValue(this.value);
    }

    @bind
    public formChange(): void{
        if (this.addressForm.valid){
            this.valueChange.emit(new AddressModel(this.addressForm.getRawValue()));
        } else {
            this.valueChange.emit(null);
        }
    }
}
