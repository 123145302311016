import { Component, effect, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEXS } from '@common-modules';
import { CustomerService } from '@services/customer/customer.service';
import { OPEN_BANKING_COMMUNICATION_TYPE, SubmitOpenBankingEventPayload } from '../payment.component';
import { OpenBankingService } from '@services/checkout/open-banking.service';

@Component({
    selector: 'tlv-open-banking',
    templateUrl: './open-banking.component.html',
    styleUrls: ['./open-banking.component.scss'],
    standalone: false
})
export class OpenBankingComponent implements OnInit {
    @Output() submitEvent: EventEmitter<SubmitOpenBankingEventPayload> = new EventEmitter<SubmitOpenBankingEventPayload>();
    @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();

    public isOpenBankingAlreadyPerformed = false;
    public isLoading = false;

    public openBankingForm: FormGroup = new FormGroup({
        phone: new FormControl<string | null>(null, [Validators.pattern(REGEXS.PHONE_REGEXP)]),
        email: new FormControl<string | null>({value: null, disabled: true}, [Validators.pattern(REGEXS.EMAIL_REGEXP)]),
        type: new FormControl<OPEN_BANKING_COMMUNICATION_TYPE>(OPEN_BANKING_COMMUNICATION_TYPE.SMS)
    });
    public OPEN_BANKING_COMMUNICATION_TYPE = OPEN_BANKING_COMMUNICATION_TYPE;

    constructor(
        private customerService: CustomerService,
        private openBankingService: OpenBankingService
    ) {
        this.openBankingForm.patchValue({
            phone: this.customerService.customer?.phone,
            email: this.customerService.customer?.email,
        });

        effect(() => {
            const status = this.openBankingService.editIban();
            if (status) {
                this.isOpenBankingAlreadyPerformed = false;
            }
        });

        effect(() => {
            this.isLoading = this.openBankingService.isLoading();
        });

    }

    public ngOnInit(): void {
        const status = this.openBankingService.status;
        this.isOpenBankingAlreadyPerformed = status.isAlreadyPerformed;
        this.openBankingForm.get('type')?.valueChanges.subscribe((value: OPEN_BANKING_COMMUNICATION_TYPE) => {
            switch (value) {
                case OPEN_BANKING_COMMUNICATION_TYPE.MAIL:
                    this.openBankingForm.get('phone')?.disable();
                    this.openBankingForm.get('email')?.enable();
                    break;
                case OPEN_BANKING_COMMUNICATION_TYPE.SMS:
                default:
                    this.openBankingForm.get('email')?.disable();
                    this.openBankingForm.get('phone')?.enable();
                    break;
            }
        });
    }

    public submitOpenBanking(): void {
        if (this.openBankingForm.valid) {
            this.submitEvent.emit(this.openBankingForm.value);
        }
    }

    public cancelOpenBanking(): void {
        this.cancelEvent.emit();
    }
}
