@if (!isLoading && !isSearching) {
  @if (commercialProposal?.length > 0) {
    @if (error) {
      <div class="column">
        <div class="resume-notification notification is-small has-body is-danger is-marginless">
          <div class="icon has-text-tertiary">
            <i class="tri-exclamation-circle"></i>
          </div>
          <div class="body">
            <p class="text is-1">{{ error }}</p>
          </div>
        </div>
      </div>
    }

    <ng-container>
      Historique des propositions commerciales établies dans les 45 derniers jours
      <table class="table is-fullwidth" data-cy="commercialProposal">
        <thead>
          <tr>
            <th>N° de la propal co</th>
            <th>Date de création</th>
            <th>Type de parcours</th>
            <th>Canal</th>
            <th>Créateur</th>
          </tr>
        </thead>
        <tbody>
          @for (proposal of commercialProposal; track proposal) {
            <tr data-cy="items" class="is-expandable">
              <td data-cy="identifiant" (click)="searchDocument(proposal.identifiant)">
                {{ proposal.identifiant }}
              </td>
              <td data-cy="dateCreation">
                {{ proposal.dateCreation | date: 'dd/MM/yyyy' }}
              </td>
              <td data-cy="typeParcours">
                {{ proposal.typeParcours }}
              </td>
              <td data-cy="canal">
                <span>{{ getCanalLabel(proposal.acteurCreateur) }}</span>
              </td>
              <td data-cy="acteurCreateur">
                <span>{{ getCreatorLabel(proposal.acteurCreateur) }}</span>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </ng-container>
  }
}
@else {
<tlv-loader loadingText="Chargement des propositions commerciales"></tlv-loader>
}