// Accessible colors
$primary-100: #5B2204 !default;
$primary-90: #813106 !default;
$primary-80: #9E3C07 !default;
$primary-70: #B64508 !default;
$primary-60: #CC4E0A !default; //primary
$primary-50: #D78572 !default;
$primary-40: #E1ACA1 !default;
$primary-30: #EBCBC5 !default;
$primary-20: #F5E6E4 !default;
$primary-10: #FAF3F1 !default;

$secondary-100: #053740 !default;
$secondary-90: #074D5B !default;
$secondary-80: #095F70 !default;
$secondary-70: #0A6E81 !default;
$secondary-60: #0C7B91 !default; //secondary
$secondary-50: #729EAC !default;
$secondary-40: #A1BBC4 !default;
$secondary-30: #C5D4D9 !default;
$secondary-20: #E4EAED !default;
$secondary-10: #F1F5F6 !default;

$tertiary-100: #101F2A !default;
$tertiary-90: #172C3C !default;
$tertiary-80: #1C3649 !default;
$tertiary-70: #213E54 !default;
$tertiary-60: #25465F !default; //tertiary
$tertiary-50: #76828E !default;
$tertiary-40: #A3AAB1 !default;
$tertiary-30: #C6CACE !default;
$tertiary-20: #E4E6E8 !default;
$tertiary-10: #F2F2F3 !default;

$grey-100: #323232 !default;
$grey-90: #464646 !default;
$grey-80: #565656 !default;
$grey-70: #646464 !default;
$grey-60: #707070 !default; //grey
$grey-50: #979797 !default;
$grey-40: #B7B7B7 !default;
$grey-30: #D1D1D1 !default;
$grey-20: #E9E9E9 !default;
$grey-10: #F4F4F4 !default;

$warning-100: #725316 !default;
$warning-90: #A17620 !default;
$warning-80: #C59027 !default;
$warning-70: #E4A72D !default;
$warning-60: #FFBB33 !default; //warning
$warning-50: #FFCA7A !default;
$warning-40: #FFD8A6 !default;
$warning-30: #FFE6C8 !default;
$warning-20: #FFF2E5 !default;
$warning-10: #FFF9F2 !default;

$success-100: #003724 !default;
$success-90: #004D33 !default;
$success-80: #005F3F !default;
$success-70: #006E49 !default;
$success-60: #007B52 !default; //suceess
$success-50: #729E87 !default;
$success-40: #A1BBAD !default;
$success-30: #C5D4CC !default;
$success-20: #E4EAE7 !default;
$success-10: #F1F5F3 !default;

$error-100: #5E1400 !default;
$error-90: #861C01 !default;
$error-80: #A42201 !default;
$error-70: #BD2801 !default;
$error-60: #D42D02 !default; //error
$error-50: #DD7872 !default;
$error-40: #E6A5A1 !default;
$error-30: #EEC7C5 !default;
$error-20: #F6E4E4 !default;
$error-10: #F6E4E4 !default;

// Colors
$black: hsl(0, 0%, 4%) !default;
$black-bis: hsl(0, 0%, 7%) !default;
$black-ter: hsl(0, 0%, 14%) !default;

$grey-darker: $grey-80 !default;
$grey-dark: $grey-70 !default;
$grey: $grey-60 !default;
$grey-disabled: $grey-30 !default;
$grey-light: $grey-20 !default;
$grey-lighter: $grey-10 !default;

$grey-shadow: rgba(0, 0, 0, 0.25) !default;
$grey-border: $grey-30 !default;
$grey-border-dark: $grey-70 !default;

$white-ter: hsl(0, 0%, 96%) !default;
$white-bis: hsl(0, 0%, 98%) !default;
$white: #fff !default;

$transparent: transparent !default;

$yellow: #ffdd57 !default;
$turquoise: #00d1b2 !default;
$cyan: #209cee !default;
$purple: #b86bff !default;
$pink: #c8007b !default;
$blue: #009dcc !default;
$cobalt: #0055a4 !default;
$green: #007b52 !default;
$orange: #fb3;
$red: #d42d02 !default;
$spray: #109db9 !default;
$arapawa: #25465f !default;
$sunset: #fe544b !default;
$persimmon: #ea5b0f !default;
$forest-green: #007B39 !default;

// Typography OS-regular
$family-sans-serif: 'Bouygues Read', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$family-serif: 'Bouygues Speak', 'Poppins', 'Arial Black', 'Arial Bold', serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$font-ratio: 1 !default;
$font-convert-to-px: false !default;

$text-8: 8px !default;
$text-9: 9px !default;
$text-10: 10px !default;
$text-11: 11px !default;
$text-12: 12px !default;
$text-13: 13px !default;
$text-14: 14px !default;
$text-15: 15px !default;
$text-16: 16px !default;
$text-17: 17px !default;
$text-18: 18px !default;
$text-19: 19px !default;
$text-20: 20px !default;
$text-22: 22px !default;
$text-24: 24px !default;
$text-26: 26px !default;
$text-27: 27px !default;
$text-28: 28px !default;
$text-29: 29px !default;
$text-32: 32px !default;
$text-34: 34px !default;
$text-35: 35px !default;
$text-36: 36px !default;
$text-38: 38px !default;
$text-39: 39px !default;
$text-40: 40px !default;
$text-42: 42px !default;
$text-44: 44px !default;
$text-46: 46px !default;
$text-48: 48px !default;
$text-50: 50px !default;
$text-54: 54px !default;
$text-56: 56px !default;
$text-58: 58px !default;
$text-60: 60px !default;
$text-64: 64px !default;
$text-80: 80px !default;


$size-1: $text-32;
$size-2: $text-28;
$size-3: $text-24;
$size-4: $text-20;
$size-5: $text-18;
$size-6: 14px !default;
$size-7: $text-12;
$size-8: $text-11;

$size-1-desktop: $text-48;
$size-2-desktop: $text-38;
$size-3-desktop: $text-28;
$size-4-desktop: $text-24;
$size-5-desktop: $text-18;
$size-6-desktop: 14px !default;
$size-7-desktop: $text-12;
$size-8-desktop: $text-11;

$weight-extrathin: 400 !default;
$weight-thin: 400 !default;
$weight-light: 400 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 600 !default;
$weight-extrabold: 600 !default;
$weight-black: 600 !default;

// Responsiveness
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 20px !default;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 768px !default;

// 960px container + 3rem
//$desktop: 960px + 2 * $gap !default;
$desktop: 984px + 2 * $gap !default;

// 1152px container + 3rem
$widescreen: 1200px + 2 * $gap !default;
$widescreen-enabled: true !default;

// 1348px container + 3rem
$fullhd: 1348px + 2 * $gap !default;
$fullhd-enabled: true !default;

// Miscellaneous
$easing: ease-out !default;
$speed: 86ms !default;

// Radius
$radius-small: 4px !default;
$radius: 8px !default;
$radius-large: 9px !default;
$radius-rounded: 6685px !default;

// Flags
$variable-columns: true !default;

// Animations
$animation-duration: 0.3s;
$animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

// Logo sizes
$logo-width: 150px;
$logo-width-desktop: 200px;
