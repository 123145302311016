import { Pipe, PipeTransform } from '@angular/core';
import { Fai } from '@bytel/bytel-sales';
import { CLASSIFICATION, FAI_COMMERCIAL_SERIES } from '../constants/fai';

@Pipe({
    name: 'playsLabel',
    standalone: false
})

export class PlaysLabelPipe implements PipeTransform {

    public transform(subscription: Fai): string {

        let plays = 'Internet';

        if (subscription.play === '1P') {
            return plays;
        }

        if (subscription.play === '3P' || subscription.data?.classification === CLASSIFICATION.MEDIUM_TV_XGBOX) {
            plays += ' - TV';
        }

        if (subscription.play === '2P' || subscription.play === '3P') {
            plays += ' - Téléphonie';
        }

        if (subscription.data.commercial_series === FAI_COMMERCIAL_SERIES.SMART_TV) {
            plays = 'Internet - TV - Téléphonie + Smart TV';
        }

        return plays;
    }
}
