@if (loading) {
  <div>
    Chargement en cours...
  </div>
} @else {
  <div class="column is-12">
    <div class="is-fullwidth is-vcentered">
      @if (showFormAddress) {
        <p class="is-size-5">{{wording.add}}</p>
      }
      @if (!showFormAddress) {
        <p class="is-size-5">{{wording.confirm}}</p>
      }
    </div>
  </div>
  @if (showFormAddress) {
    <ng-container  [data-cy]="'edit-manual'">
      @if (isAutomatic) {
        <tlv-automatic-address [data-cy]="'address-auto'"
          [(value)]="deliveryAddress"
          (valueChange)="onDeliveryAddressChange($event)"
          >
        </tlv-automatic-address>
      }
      @if (!isAutomatic) {
        <tlv-manual-address [data-cy]="'address-manual'"
          [(value)]="deliveryAddress"
          (valueChange)="deliveryAddress = $event">
        </tlv-manual-address>
      }
      <div class="column is-12">
        <div class="columns is-multiline">
          @if (isAutomatic) {
            <div class="column is-5">
              <a (click)="switchMode()" class="link has-text-info is-size-7"  [data-cy]="'switch-mode'">
                Vous ne trouvez pas votre adresse ?
              </a>
            </div>
          }
          <div class="column">
            <button [data-cy]="'address-submit'" (click)="submit()" class="button is-primary" [disabled]="!deliveryAddress">{{ loadingAddress ? 'Traitement adresse...' : 'Continuer' }}</button>
          </div>
        </div>
      </div>
    </ng-container>
  } @else {
    @if ((deliveryAddress?.confirmed || deliveryAddress?.valid) && !shippingModesError) {
      <div class="column" [data-cy]="'address-valide'">
        <div class="columns is-multiline">
          <div class="column is-narrow-tablet is-centered-mobile is-flex is-vcentered">
            <span class="columns is-centered is-mobile is-multiline">
              <span class="icon is-medium column is-12-mobile is-narrow-tablet"><i class="tri-marker"></i></span>
              <span class="is-uppercase column is-12-mobile unshrink-line" data-cy="inline-address-valid">{{deliveryAddress?.getInline()}}</span>
            </span>
          </div>
          @if (editable || !deliveryAddress?.valid) {
            <div class="column is-narrow">
              <a (click)="enableModification()" [data-cy]="'edit-address'" class="is-link">Modifier l'adresse</a>
            </div>
          }
        </div>
      </div>
    } @else {
      <div class="column is-8 is-offset-2" [data-cy]="'address-confirmation'">
        <div class="columns is-multiline has-text-centered">
          <div class="column is-12">
            <p class="text is-2" data-cy="inline-address-editable">{{deliveryAddress?.getInline()}}</p>
          </div>
          @if (shippingModesError) {
            <div class="column is-12">
              <div class="notification is-danger is-small has-body">
                <div class="icon">
                  <i class="tri-exclamation-circle"></i>
                </div>
                <div class="body">
                  <h5 class="title">
                    {{shippingModesError}}
                  </h5>
                </div>
              </div>
            </div>
            <div class="column buttons is-12">
              <button type="submit" (click)="enableModification(); shippingModesError = null;" class="button is-secondary">Modifier</button>
            </div>
          } @else {
            <div class="column is-12" data-cy="not-valid-address-notification">
              <div class="notification is-small has-body">
                <div class="icon">
                  <i class="tri-infos-circle"></i>
                </div>
                <div class="body">
                  <h5 class="title">Veuillez vous assurer de la bonne saisie de votre adresse.</h5>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="buttons is-justified-center">
                <button type="submit" (click)="enableModification()" class="button is-secondary"  [data-cy]="'edit-address'">Modifier</button>
                <button type="submit" (click)="confirm()" class="button is-primary"  [data-cy]="'confirm-address'">Confirmer</button>
              </div>
            </div>
          }
        </div>
      </div>
    }
  }
}
