import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BasicObject, Oauth2ResourcesService } from '@common-modules';
import { ICampaignApiResponse, ICampaignItem, ICampaignTypeData, ICampaignTypes } from '@interfaces/campaigns.interface';
import { QualificationModel } from '@models/qualification';
import { CallRecordService } from '@services/call-record.service';
import { QualificationService } from '@services/qualification.service';
import { SalesForceService } from '@services/salesforce.service';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { CustomerService } from '@services/customer/customer.service';
import { DialogRef } from '@ngneat/dialog';
import { SalesUserService } from '@services/sales-user.service';
import { OpportunityService } from '@services/opportunity.service';
import { ScoringService } from '@services/checkout/scoring.service';
import {CLUSTER, ClusterService} from '@services/cluster.service';

enum STEPS_NAMES {
    QUALIFICATION,
    CUSTOMER_SEARCH
}

@Component({
    selector: 'tlv-call-qualification',
    templateUrl: './call-qualification.component.html',
    styleUrls: ['./call-qualification.component.scss'],
    standalone: false
})
export class CallQualificationComponent implements OnInit {

    public isLoading = true;
    public isLoadingData = false;
    public campaignKeys: ICampaignTypes = {
        campagnesEntrant: { label: 'campagnesEntrant', value: 'in' },
        campagnesSortant: { label: 'campagnesSortant', value: 'out' }
    };
    public campaignsList: ICampaignApiResponse = {
        campagnesEntrant: [],
        campagnesSortant: []
    };
    public campaignForm: FormGroup = this.fb.group({
        calltype: [
            null,
            Validators.required
        ],
        campaign: [
            null,
            Validators.required
        ],
        customerSearch: new FormControl<boolean | null>(null, [Validators.required])
    });
    public error: string;
    public canSearchCustomer = false;
    public isCustomerSearchCompleted = false;
    public STEPS_NAMES: typeof STEPS_NAMES = STEPS_NAMES;
    public currentStep: number = STEPS_NAMES.QUALIFICATION;
    public isClientLockByScoring = false;
    public redirectAfterSearchUrl = 'products';

    constructor(
        private oauth2ResourcesService: Oauth2ResourcesService,
        private fb: UntypedFormBuilder,
        private qualificationService: QualificationService,
        private salesForceService: SalesForceService,
        private router: Router,
        private customerService: CustomerService,
        private callRecordService: CallRecordService,
        private salesUserService: SalesUserService,
        private opportunityService: OpportunityService,
        private scoringService: ScoringService,
        private clusterService: ClusterService,
    ) { }

    public ngOnInit(): void {
        this._buildForm();
        this._initCampaignLists();
        const hasSalesForceInfos: boolean = this.salesForceService.prefilledInfo?.hasQalificationInfos();
        this.scoringService.scoring$.subscribe(() =>
            this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring()
        );
        this.qualificationService.update(
            new QualificationModel({...this.qualificationService.qualification,
                ...this.salesForceService.prefilledInfo.getQualification()})
        );
        if (hasSalesForceInfos){
            this._prefillSalesForceData();
        }
    }

    public updateQualifictionData(data: BasicObject): void {
        this.campaignForm.patchValue({
            ...data,
        });
    }

    public validateQualificationCall(): void {

        this.isLoadingData = true;
        const qualificationInfos: QualificationModel = new QualificationModel({
            ...this.qualificationService.qualification,
            calltype: this.campaignKeys[this.campaignForm.getRawValue()?.calltype].value,
            campaign: this.campaignForm.getRawValue().campaign?.libelle
        });
        if (this.salesForceService?.prefilledInfo?.idDadCase) {
            this.opportunityService.onChangeOpportunityDad(
                this.salesForceService?.prefilledInfo?.idDadCase, 
                this.salesForceService?.prefilledInfo?.idOpportunity
            )
        }
        this.qualificationService.update(qualificationInfos);
        this.callRecordService.start();

        if (this.qualificationService.isDadCampaign()) {
            const dialogRef: DialogRef<any> = this.opportunityService.openOpportunityDadModal();
            dialogRef.afterClosed$.subscribe((results: Record<string, any>) => {
                if (!results?.continue) {
                    this.salesUserService.closeCall();
                } else {
                    this._navigateNextQualification();
                }
            });
        } else {
            this._navigateNextQualification();
        }
    }

    public endCustomerSearch(): void {
        this.isCustomerSearchCompleted = true;
        this.validateQualificationCall();
    }

    public previousStep(): void {
        switch (this.isCustomerSearchCompleted) {
            case true:
                this.isCustomerSearchCompleted = false;
                this.currentStep = STEPS_NAMES.CUSTOMER_SEARCH;
                break;
            case false:
            default:
                this.currentStep = STEPS_NAMES.QUALIFICATION;
                this.campaignForm.reset();
                break;
        }
    }

    private _navigateNextQualification(): void {
        this.customerService.searchCustomerIhm.next(true);
        if (this.salesForceService.prefilledInfo.salesType === 'FAI' &&
            !this.salesForceService.prefilledInfo?.customer?.idPerson &&
            !this.salesForceService.prefilledInfo?.customer?.email
        ){
            this.router.navigate(['fai']);
        } else {
            this.router.navigate([this.redirectAfterSearchUrl]);
        }
    }

    private _buildForm(): void {

        this.campaignForm = this.fb.group({
            calltype: [
                null,
                Validators.required
            ],
            campaign: [
                null,
                Validators.required
            ],
            customerSearch: [
                null,
                Validators.required
            ]
        });

        this.campaignForm.controls.campaign.statusChanges.subscribe(
            (status) => {
                this.canSearchCustomer = status === 'VALID';
                if (this.currentStep === STEPS_NAMES.CUSTOMER_SEARCH) {
                    return;
                }
                this.currentStep = status === 'VALID' ? STEPS_NAMES.CUSTOMER_SEARCH : STEPS_NAMES.QUALIFICATION;
            });
        this.campaignForm.valueChanges.subscribe(
            () => this.error = ''
        );

        this.campaignForm.get('calltype').valueChanges.subscribe(() => {
            this.campaignForm.controls.campaign.setValue(null);
            this.campaignForm.get('campaign').updateValueAndValidity();
        });

        this.campaignForm.get('campaign').valueChanges.subscribe(() => {
            this.campaignForm.setErrors(null, { emitEvent: false });
        });
    }

    private _prefillSalesForceData(): void {
        if (!this.qualificationService.qualification){return;}
        const { calltype, campaign } = this.qualificationService.qualification;
        let customerCalltype: ICampaignTypeData;

        switch (calltype?.toLowerCase()) {
            case 'in':
                customerCalltype = this.campaignKeys.campagnesEntrant;
                break;
            case 'out':
                customerCalltype = this.campaignKeys.campagnesSortant;
                break;
            case 'inconnu':
            default:
                customerCalltype = null;
        }
        const customerCampaign: ICampaignItem = campaign && this.campaignsList[customerCalltype?.label] &&
            this.campaignsList[customerCalltype?.label].find(
                (campaignItem: ICampaignItem) => campaignItem.libelle.toUpperCase() === campaign.toUpperCase()
            );

        this.campaignForm.patchValue({
            calltype: customerCalltype?.label,
            campaign: customerCampaign,
        });
    }

    private _initCampaignLists(): void {

        this.oauth2ResourcesService
            .useSalesApi()
            .ventes()
            .campagnesTelevente()
            .get()
            .pipe(
                map((data: ICampaignApiResponse) => {
                    Object.keys(data).forEach((key: string) => {
                        data[key] = data[key].sort((a, b) => a.libelle.localeCompare(b.libelle));
                    });
                    return data;
                }),
                tap((data: ICampaignApiResponse) => {
                    this.campaignsList.campagnesEntrant = data.campagnesEntrant;
                    this.campaignsList.campagnesSortant = data.campagnesSortant;
                    this.isLoading = false;
                }),
                catchError(() => {
                    this.isLoading = false;
                    this.error = 'Impossible de récupérer la liste des campagnes, veuillez réessayer';
                    return of([]);
                }),
                finalize(() => {
                    if (this.qualificationService?.qualification?.hasData()) {
                        this._forceCampaignTlv();
                    }
                })
            ).subscribe();
    }

    private _forceCampaignTlv(): void {
        const callType = this.qualificationService.qualification.calltype;
        if (this.clusterService.userIsInCluster(CLUSTER.TLV)) {
            this.campaignForm.patchValue(
                {
                    calltype: callType === 'in' ? this.campaignKeys.campagnesEntrant?.label : this.campaignKeys.campagnesSortant?.label,
                    campaign: this.campaignsList.campagnesEntrant.find(
                        (campaignItem: ICampaignItem) => campaignItem.libelle.toUpperCase() === 'TLV'
                    )
                });
        } else {
            this._prefillSalesForceData();
        }
    }

}
