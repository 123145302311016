import { Injectable } from '@angular/core';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CartSimoStep implements IStep{

    public icon = 'cart';
    public name = 'Panier';
    public label = 'Récapitulatif Mobile';
    public route: string[] = ['panier','mobile'];
    public canActive = false;
    public canBeValidate = true;
    public status: STEP_STATUS = STEP_STATUS.WAITING;

    constructor(
        private readonly cartService: CartTeleSalesService,
        private toastService: HotToastService
    ){}

    public validateStep(): boolean {
        try {
            return this.cartService.cartModel.getQuote()?.checkIsValid();
        } catch (err) {
            this.toastService.warning(err.message, { duration: 1000 });
            return false;
        }
    }

    public canAccess(): boolean {
        return this.canDisplay();
    }

    public canActivate(): Observable<boolean>  {
        return this.cartService.resolve().pipe(map(()=>this.canAccess()));
    }

    public canDisplay(): boolean {
        return !this.cartService.cartModel?.isCartEmpty() && this.cartService.isQuoteMixed();
    }

}
