import { Location } from '@angular/common';
import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { CheckoutService } from '@services/checkout/checkout.service';
import { SalesForceService } from '@services/salesforce.service';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import { PrismeLoggerService } from '@services/prisme-logger.service';
import { PrismeLogType } from './constants/prisme';

@Component({
    selector: 'tlv-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AppComponent implements AfterViewInit {

    public isLoading = true;
    public isLoadingOrder = false;

    constructor(private prismeLoggerService: PrismeLoggerService,
                private checkoutService: CheckoutService,
                private salesforceService: SalesForceService,
                private location: Location,
                private router: Router) {
        this.isLoadingOrder = this.location.path().toLowerCase().includes('commandeid')
            || !!this.salesforceService.prefilledInfo.orderId;

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            this.prismeLoggerService.sendDataToPrisme(PrismeLogType.URL_CHANGE, {url:event.url});
        });
    }

    public ngAfterViewInit(): void {
        this.checkoutService.modalSubject.next(false);
        this.checkoutService.modalReady = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public disableLoader(comp: any): void {
        this.isLoading = false;
    }

}
