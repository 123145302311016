import { Injectable } from '@angular/core';
import { Oauth2Service } from '@common-modules';
import { Fai, FaimUnlimited, ProductFactory, QuoteContextFaiModel } from '@bytel/bytel-sales';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CustomerService } from '@services/customer/customer.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PortabilityInfoStep implements IStep{

    public icon = 'tri-box-add';
    public name = 'Conservation du numéro';
    public label = 'Portabilité';
    public route: string[] = [
        'panier',
        'portability'
    ];
    public status: STEP_STATUS = STEP_STATUS.WAITING;
    public canBeValidate = false;

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        public oauth2Service: Oauth2Service,
        private customerService: CustomerService) {
    }

    public validateStep(): boolean {
        return true;
    }

    public canAccess(): boolean {
        return this.customerService.customer?.isUpdated && this.canDisplay();
    }

    public canActivate(): Observable<boolean> {
        return this.cartTeleSalesService.resolve().pipe(map(()=>this.canAccess()));
    }

    public canDisplay(): boolean {
        const index = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        const fai: Fai = this.cartTeleSalesService.cartModel?.getQuote(index)?.getProductByType(Fai);
        return fai && !ProductFactory.Is(fai, FaimUnlimited, true);
    }
}
