import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchBy',
    pure: true,
    standalone: false
})
export class SearchByPipe implements PipeTransform {

    public transform<T>(items: T[], searchText: string, key: string): T[] { // name propriety make error
        if (!items) {
            return [];
        } else if (!searchText) {
            return items;
        }

        return items.filter(product => product[key]?.toLowerCase().includes(searchText.toLowerCase()));
    }
}
