import { Pipe, PipeTransform } from '@angular/core';
import { Fai, ProductFactory, Subscription } from '@bytel/bytel-sales';
import { FAI_COMMERCIAL_SERIES } from '../constants/fai';

@Pipe({
    name: 'isSmartTV',
    standalone: false
})

export class SmartTVPipe implements PipeTransform {

    transform(plan: Subscription): boolean {
        if (plan && ProductFactory.Is(plan, Fai)) {
            return plan.data.commercial_series === FAI_COMMERCIAL_SERIES.SMART_TV;
        } else {
            return false;
        }
    }

}

