<div class="tabs is-centered" role="tablist">
  <ul>
    @for (tab of tabs | keyvalue: keepOrder; track tab) {
      <li role="presentation">
        <a (click)="changeTab(tab.key)" [data-cy]="'proposal-'+tab.value.label" role="tab"  class="input-has-badge" [ngClass]="{'is-active': tab.value.label === currentTab.label}" aria-selected="true">
          {{tab.value.title}}
          @if (tab.value.count || tab.value?.data?.length) {
            <span class="badge">{{ tab.value?.data?.length || tab.value.count }}</span>
          }
        </a>
      </li>
    }
  </ul>
</div>
@if (tabs.line && currentTab.title === tabs.line.title) {
  <div >
    <tlv-line [isLoading]="isLoading" [customer]="customer" (savedEvent)="close($event)"></tlv-line>
  </div>
}

@if(enabledSearchPropalco)
  {
  @if (currentTab.title === tabs.commercialProposal.title) {
    <div>
      @if (!isLoading && tabs.commercialProposal.data.length === 0) {
        <div>
          <span>Aucune proposition commerciale n’est disponible</span>
        </div>
      }
      <tlv-commercial-proposal [isLoading]="isLoading" [isClientLockByScoring]="isClientLockByScoring" (savedEvent)="close($event)" [commercialProposal]="$any(tabs.commercialProposal.data)"></tlv-commercial-proposal>
    </div>
  }
}


@if (currentTab.title === tabs.abandonedCart.title) {
  <div>
    @if (!isLoading && tabs.abandonedCart.data.length === 0) {
      <div>
        <span>Paniers non trouvés</span>
      </div>
    }
    <tlv-cart-list [isLoading]="isLoading" [isClientLockByScoring]="isClientLockByScoring" (savedEvent)="close($event)" [recoveryCarts]="$any(tabs.abandonedCart.data)"></tlv-cart-list>
  </div>
}

@if (currentTab.title === tabs.order.title) {
  <div>
    @if (!isLoading && tabs.order.data.length === 0) {
      <div>
        <span>Commandes non trouvées</span>
      </div>
    }
    <tlv-cart-list [isLoading]="isLoading"   [isClientLockByScoring]="isClientLockByScoring"(savedEvent)="close($event)" [recoveryCarts]="$any(tabs.order.data)"></tlv-cart-list>
  </div>
}
