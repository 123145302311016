<div class="box">
  <div class="box-content">
    <div class="columns is-multiline is-marginless is-centered" data-cy="option-modal">
      @if (isLoading && !description) {
        <div class="column is-12 has-text-centered">
          <a class="icon is-large">
            <i class="tri-sync"></i>
          </a>
        </div>
      }
      @if (description) {
        <div class="column is-12 has-text-centered">
          <h3 class="is-title is-size-3">{{ref.data.product.name}}</h3>
          <p [innerHTML]="description">Chargement de la description...</p>
        </div>
        <div class="column is-12">
          <div class="columns is-justified-center is-vcentered">
            <div class="column is-narrow">
              <tlv-price [hasPeriod]="true" [price]="ref.data.product.prices.final" size-3></tlv-price>
              <p>
                @if (ref.data.product.data.obligation) {
                  <span>
                    <span>{{ref.data.product.data.obligation|engagementLabel}}</span>
                  </span>
                }
                @if (!ref.data.product.data.obligation) {
                  <span>Sans engagement</span>
                }
              </p>
            </div>
            <div class="column is-narrow">
                            <button class="button" (click)="addOrRemoveOption()" [disabled]="!ref.data.product.available && buttonLabel === BUTTON_LABELS.add" [ngClass]="{
                                'is-loading': isLoading,
                                'is-primary': buttonLabel === BUTTON_LABELS.add,
                                'is-tertiary': buttonLabel === BUTTON_LABELS.remove}
                            ">
                <span>{{buttonLabel}}</span>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>