import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONTRACT_STATUS } from '@interfaces/contract.interface';
import { CustomerProGpSearchModel } from '@interfaces/customer.interface';
import { CustomerProSearchModel } from '@models/customer/customer-pro-search.model';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { ScoringService } from '@services/checkout/scoring.service';
import { BillingContract } from '@models/customer/billing-account.model';
import { compareDesc, parseISO } from 'date-fns';
import { ConfigurationService } from '@common-modules';

@Component({
    selector: 'tlv-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss'],
    standalone: false
})
export class LineComponent implements OnInit {

    @Input() public customer: CustomerProGpSearchModel;
    @Input() public isLoading = false;
    @Output() public savedEvent: EventEmitter<string> = new EventEmitter<string>();

    public isBtnValid = false;
    public customerPro: CustomerProSearchModel;
    public customerGp: CustomerSearchModel;
    public isClientLockByScoring = false;
    public contracts: BillingContract[] = [];

    constructor(
        private scoringService: ScoringService,
        private configService: ConfigurationService
    ) {
    }

    public ngOnInit(): void {
        if (this.customer instanceof CustomerProSearchModel){
            this.customerPro = this.customer;
        } else {
            this.customerGp = this.customer;
        }
        this._sortContracts();
        this.isBtnValid = this.customer.comptesFacturations.some((compteFacturation) =>
            compteFacturation.contratsPayes.some((contract) => CONTRACT_STATUS.ACTIVE === contract.statut));

        this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring();

        this.scoringService.scoring$.subscribe(() => {
            this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring();
        });
    }

    public nextStep(): void {
        this.savedEvent.emit('saved');
    }

    private _sortContracts(): void {
        const contracts = this.customer.comptesFacturations
            .flatMap((account) => account.contratsPayes)
            .sort(this._sortContractsByStatusAndDate.bind(this));
        this.contracts = this._filterTerminatedContracts(contracts);
    }

    private _sortContractsByStatusAndDate(c1, c2): number {
        const statutDiff = CONTRACT_STATUS.ACTIVE.indexOf(c2.statut) - CONTRACT_STATUS.ACTIVE.indexOf(c1.statut);
        if (statutDiff === 0) {
            return compareDesc(parseISO(`${c1.dateCreation}`), parseISO(`${c2.dateCreation}`));
        }
        return statutDiff;
    }

    private _filterTerminatedContracts(contracts): BillingContract[] {
        let nbTerminated = 0;
        const maxTerminated = this.configService.data_refconf.maxTerminated;
        return contracts.filter((contract) => {
            if (contract.statut === CONTRACT_STATUS.TERMINATED && nbTerminated < maxTerminated) {
                nbTerminated++;
                return true;
            }
            return contract.statut !== CONTRACT_STATUS.TERMINATED;
        });
    }

}
